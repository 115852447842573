import {
    MapContainer,
    LayersControl,
    Polygon,
    Marker,
    TileLayer,
    WMSTileLayer,
    useMapEvents,
    Polyline,
    LayerGroup,
} from 'react-leaflet';
import {
    iconAdr,
    iconAkp,
    iconAmbulanse,
    iconAndre,
    iconBaal,
    iconBrann,
    iconBrannbaat,
    iconBrannbil,
    iconBrannhelikopter,
    iconBrannmann,
    iconDepot,
    iconDykkertjeneste,
    iconEksplosiver,
    iconFare,
    iconFlom,
    iconForf,
    iconHelikopterlandingsplass,
    iconHjertestarter,
    iconHundepatrulje,
    iconInformasjon,
    iconKo,
    iconLege,
    iconLegehelikopter,
    iconMannoverbord,
    iconMilitaer,
    iconMoeteplass,
    iconParkering,
    iconPolitibaat,
    iconPolitibil,
    iconPolitihelikopter,
    iconPolitimann,
    iconRadioaktiv,
    iconRedningsskoyte,
    iconRedningshund,
    iconSamleplass,
    iconSivilforsvar,
    iconSkred,
    iconSnoscooter,
    iconVegsperring,
    iconVenteplass,
    iconHendelsesInfo,
    iconTarget,
} from './MapIcons';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Box } from '@material-ui/core';
function MapActions(props) {
    const map = useMapEvents({
        click: (e) => {
            props.handleMapClick(e);
        },
    });
    if (props.position) {
        map.flyTo(props.position, 16);
        props.setPosition(null);
    }
    return null;
}

const OpenStreetMapV2 = (props) => {
    let poi = [];
    let poly = { red: [], green: [], yellow: [], other: [] };
    let line = [];
    if (props.mapLocations) {
        props.mapLocations.forEach((loc) => {
            if (loc.type === 1) {
                poi.push(loc);
            }
            if (loc.type === 2) {
                if (loc.polyColor === 'red') {
                    poly.red.push(loc);
                } else if (loc.polyColor === 'yellow') {
                    poly.yellow.push(loc);
                } else if (loc.polyColor === 'green') {
                    poly.green.push(loc);
                } else {
                    poly.other.push(loc);
                }
            }
            if (loc.type === 3) {
                line.push(loc);
            }
        });
    }
    if (props.incident) {
        return (
            <>
                {!props.incident.ended && (
                    <Box style={{ height: '100%', width: '100%', flex: 1 }}>
                        <MapContainer
                            center={props.incident.position}
                            zoom={14}
                            scrollWheelZoom={true}
                            style={{ height: '100%' }}
                        >
                            <MapActions
                                handleMapClick={props.handleMapClick}
                                position={props.position}
                                setPosition={props.setPosition}
                            />
                            <LayersControl position='topright'>
                                <LayersControl.BaseLayer
                                    checked
                                    name='Open street map'
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                    />
                                </LayersControl.BaseLayer>
                                <LayersControl.BaseLayer name='Norge Topo'>
                                    <WMSTileLayer
                                        format='image/png'
                                        layers='topo4'
                                        url='http://opencache.statkart.no/gatekeeper/gk/gk.open'
                                        attribution='&copy; <a href="http://kartverket.no">Kartverket</a>'
                                    />
                                </LayersControl.BaseLayer>
                                <LayersControl.Overlay name='Strømnett'>
                                    <WMSTileLayer
                                        format='image/png'
                                        layers='Sentralnett'
                                        transparent={true}
                                        url='https://gis3.nve.no/map/services/Nettanlegg2/MapServer/WmsServer?'
                                        attribution='&copy; <a href="https://www.nve.no/">NVE</a>'
                                    />
                                </LayersControl.Overlay>
                                <LayersControl.Overlay
                                    checked
                                    name='Hendelsesikon'
                                >
                                    <Marker
                                        position={props.incident.position}
                                        icon={iconHendelsesInfo}
                                        eventHandlers={{
                                            click: () => {
                                                if (
                                                    !props.createPolyLine &&
                                                    !props.createPoly
                                                ) {
                                                    props.setToggleInfo(true);
                                                }
                                            },
                                        }}
                                    />
                                </LayersControl.Overlay>
                                <LayersControl.Overlay checked name='POI'>
                                    <MarkerClusterGroup>
                                        {poi.map((loc) => {
                                            let position = [
                                                loc.geo[0].lat,
                                                loc.geo[0].lng,
                                            ];
                                            let currentIcon = iconInformasjon;
                                            if (loc.iconType) {
                                                switch (loc.iconType) {
                                                    case 'Farlig gods':
                                                        currentIcon = iconAdr;
                                                        break;
                                                    case 'Ambulansekontrollpunkt':
                                                        currentIcon = iconAkp;
                                                        break;
                                                    case 'Ambulanse':
                                                        currentIcon = iconAmbulanse;
                                                        break;
                                                    case 'Andre ressurser':
                                                        currentIcon = iconAndre;
                                                        break;
                                                    case 'Bål':
                                                        currentIcon = iconBaal;
                                                        break;
                                                    case 'Brann':
                                                        currentIcon = iconBrann;
                                                        break;
                                                    case 'Brannbåt':
                                                        currentIcon = iconBrannbaat;
                                                        break;
                                                    case 'Brannbil':
                                                        currentIcon = iconBrannbil;
                                                        break;
                                                    case 'Brannhelikopter':
                                                        currentIcon = iconBrannhelikopter;
                                                        break;
                                                    case 'Brannmann':
                                                        currentIcon = iconBrannmann;
                                                        break;
                                                    case 'Depot':
                                                        currentIcon = iconDepot;
                                                        break;
                                                    case 'Dykkertjeneste':
                                                        currentIcon = iconDykkertjeneste;
                                                        break;
                                                    case 'Eksplosiver':
                                                        currentIcon = iconEksplosiver;
                                                        break;
                                                    case 'Fare':
                                                        currentIcon = iconFare;
                                                        break;
                                                    case 'Flom':
                                                        currentIcon = iconFlom;
                                                        break;
                                                    case 'FORF':
                                                        currentIcon = iconForf;
                                                        break;
                                                    case 'Helikopterlandingsplass':
                                                        currentIcon = iconHelikopterlandingsplass;
                                                        break;
                                                    case 'Hjertestarter':
                                                        currentIcon = iconHjertestarter;
                                                        break;
                                                    case 'Hundepatrulje':
                                                        currentIcon = iconHundepatrulje;
                                                        break;
                                                    case 'Informasjon':
                                                        currentIcon = iconInformasjon;
                                                        break;
                                                    case 'KO':
                                                        currentIcon = iconKo;
                                                        break;
                                                    case 'Lege':
                                                        currentIcon = iconLege;
                                                        break;
                                                    case 'Luftambulanse':
                                                        currentIcon = iconLegehelikopter;
                                                        break;
                                                    case 'Mann over bord':
                                                        currentIcon = iconMannoverbord;
                                                        break;
                                                    case 'Militær':
                                                        currentIcon = iconMilitaer;
                                                        break;
                                                    case 'Møteplass':
                                                        currentIcon = iconMoeteplass;
                                                        break;
                                                    case 'Parkering':
                                                        currentIcon = iconParkering;
                                                        break;
                                                    case 'Politibåt':
                                                        currentIcon = iconPolitibaat;
                                                        break;
                                                    case 'Politibil':
                                                        currentIcon = iconPolitibil;
                                                        break;
                                                    case 'Politihelikopter':
                                                        currentIcon = iconPolitihelikopter;
                                                        break;
                                                    case 'Politimann':
                                                        currentIcon = iconPolitimann;
                                                        break;
                                                    case 'Radioaktiv':
                                                        currentIcon = iconRadioaktiv;
                                                        break;
                                                    case 'Redningshund':
                                                        currentIcon = iconRedningshund;
                                                        break;
                                                    case 'Redningsskøyte':
                                                        currentIcon = iconRedningsskoyte;
                                                        break;
                                                    case 'Samleplass':
                                                        currentIcon = iconSamleplass;
                                                        break;
                                                    case 'Sivilforsvar':
                                                        currentIcon = iconSivilforsvar;
                                                        break;
                                                    case 'Skred':
                                                        currentIcon = iconSkred;
                                                        break;
                                                    case 'Snøscooter':
                                                        currentIcon = iconSnoscooter;
                                                        break;
                                                    case 'Vegsperring':
                                                        currentIcon = iconVegsperring;
                                                        break;
                                                    case 'Venteplass':
                                                        currentIcon = iconVenteplass;
                                                        break;
                                                    default:
                                                        currentIcon = iconInformasjon;
                                                }
                                            }
                                            return (
                                                <Marker
                                                    position={position}
                                                    key={loc.mapLocId}
                                                    icon={currentIcon}
                                                    eventHandlers={{
                                                        click: () => {
                                                            if (
                                                                !props.createPolyLine &&
                                                                !props.createPoly
                                                            ) {
                                                                props.setTogglePoi(
                                                                    true
                                                                );
                                                                props.setActivePoi(
                                                                    loc
                                                                );
                                                            }
                                                        },
                                                    }}
                                                />
                                            );
                                        })}
                                    </MarkerClusterGroup>
                                </LayersControl.Overlay>
                                <LayersControl.Overlay checked name='Polygoner'>
                                    <LayerGroup>
                                        {poly.other &&
                                            poly.other.map((loc) => {
                                                let positions = [];
                                                loc.geo.forEach((elm) => {
                                                    positions.push([
                                                        elm.lat,
                                                        elm.lng,
                                                    ]);
                                                });
                                                return (
                                                    <Polygon
                                                        key={loc.mapLocId}
                                                        pathOptions={{
                                                            color: 'black',
                                                            fillColor:
                                                                loc.polyColor,
                                                            fillOpacity: '20%',
                                                        }}
                                                        positions={positions}
                                                        eventHandlers={{
                                                            click: () => {
                                                                if (
                                                                    !props.createPolyLine &&
                                                                    !props.createPoly
                                                                ) {
                                                                    props.setTogglePoi(
                                                                        true
                                                                    );
                                                                    props.setActivePoi(
                                                                        loc
                                                                    );
                                                                }
                                                            },
                                                        }}
                                                    />
                                                );
                                            })}
                                        {poly.green &&
                                            poly.green.map((loc) => {
                                                let positions = [];
                                                loc.geo.forEach((elm) => {
                                                    positions.push([
                                                        elm.lat,
                                                        elm.lng,
                                                    ]);
                                                });
                                                return (
                                                    <Polygon
                                                        key={loc.mapLocId}
                                                        pathOptions={{
                                                            color: 'black',
                                                            fillColor:
                                                                loc.polyColor,
                                                            fillOpacity: '20%',
                                                        }}
                                                        positions={positions}
                                                        eventHandlers={{
                                                            click: () => {
                                                                if (
                                                                    !props.createPolyLine &&
                                                                    !props.createPoly
                                                                ) {
                                                                    props.setTogglePoi(
                                                                        true
                                                                    );
                                                                    props.setActivePoi(
                                                                        loc
                                                                    );
                                                                }
                                                            },
                                                        }}
                                                    />
                                                );
                                            })}
                                        {poly.yellow &&
                                            poly.yellow.map((loc) => {
                                                let positions = [];
                                                loc.geo.forEach((elm) => {
                                                    positions.push([
                                                        elm.lat,
                                                        elm.lng,
                                                    ]);
                                                });
                                                return (
                                                    <Polygon
                                                        key={loc.mapLocId}
                                                        pathOptions={{
                                                            color: 'black',
                                                            fillColor:
                                                                loc.polyColor,
                                                            fillOpacity: '20%',
                                                        }}
                                                        positions={positions}
                                                        eventHandlers={{
                                                            click: () => {
                                                                if (
                                                                    !props.createPolyLine &&
                                                                    !props.createPoly
                                                                ) {
                                                                    props.setTogglePoi(
                                                                        true
                                                                    );
                                                                    props.setActivePoi(
                                                                        loc
                                                                    );
                                                                }
                                                            },
                                                        }}
                                                    />
                                                );
                                            })}
                                        {poly.red &&
                                            poly.red.map((loc) => {
                                                let positions = [];
                                                loc.geo.forEach((elm) => {
                                                    positions.push([
                                                        elm.lat,
                                                        elm.lng,
                                                    ]);
                                                });
                                                return (
                                                    <Polygon
                                                        key={loc.mapLocId}
                                                        pathOptions={{
                                                            color: 'black',
                                                            fillColor:
                                                                loc.polyColor,
                                                            fillOpacity: '20%',
                                                        }}
                                                        positions={positions}
                                                        eventHandlers={{
                                                            click: () => {
                                                                if (
                                                                    !props.createPolyLine &&
                                                                    !props.createPoly
                                                                ) {
                                                                    props.setTogglePoi(
                                                                        true
                                                                    );
                                                                    props.setActivePoi(
                                                                        loc
                                                                    );
                                                                }
                                                            },
                                                        }}
                                                    />
                                                );
                                            })}
                                    </LayerGroup>
                                </LayersControl.Overlay>
                                <LayersControl.Overlay checked name='Linjer'>
                                    <LayerGroup>
                                        {line.map((loc) => {
                                            let positions = [];
                                            loc.geo.forEach((elm) => {
                                                positions.push([
                                                    elm.lat,
                                                    elm.lng,
                                                ]);
                                            });
                                            return (
                                                <Polyline
                                                    key={loc.mapLocId}
                                                    pathOptions={{
                                                        color: 'black',
                                                        weight:
                                                            loc.polyLineWidth,
                                                    }}
                                                    positions={positions}
                                                    eventHandlers={{
                                                        click: () => {
                                                            if (
                                                                !props.createPolyLine &&
                                                                !props.createPoly
                                                            ) {
                                                                props.setTogglePoi(
                                                                    true
                                                                );
                                                                props.setActivePoi(
                                                                    loc
                                                                );
                                                            }
                                                        },
                                                    }}
                                                />
                                            );
                                        })}
                                    </LayerGroup>
                                </LayersControl.Overlay>
                            </LayersControl>
                            {props.polyData && (
                                <Polygon
                                    pathOptions={{
                                        color: 'black',
                                        fillColor: props.polyColor,
                                        fillOpacity: '20%',
                                    }}
                                    positions={props.polyData}
                                />
                            )}
                            {props.polyLineData.length > 0 && (
                                <Polyline
                                    pathOptions={{
                                        color: 'black',
                                        weight: props.polyLineWidth,
                                    }}
                                    positions={props.polyLineData}
                                />
                            )}
                            {props.poiTmpData && (
                                <Marker
                                    position={[
                                        props.poiTmpData.lat,
                                        props.poiTmpData.lng,
                                    ]}
                                    icon={iconTarget}
                                ></Marker>
                            )}
                        </MapContainer>
                    </Box>
                )}
            </>
        );
    } else {
        return null;
    }
};
export default OpenStreetMapV2;
