import L from 'leaflet';
import adr from '../assets/mapIcons4/adr.svg';
import akp from '../assets/mapIcons4/akp.svg';
import ambulanse from '../assets/mapIcons4/ambulanse.svg';
import andreressurser from '../assets/mapIcons4/andreressurser.svg';
import baal from '../assets/mapIcons4/baal.svg';
import brann from '../assets/mapIcons4/brann.svg';
import brannbaat from '../assets/mapIcons4/brannbaat.svg';
import brannbil from '../assets/mapIcons4/brannbil.svg';
import brannhelikopter from '../assets/mapIcons4/brannhelikopter.svg';
import brannmann from '../assets/mapIcons4/brannmann.svg';
import depot from '../assets/mapIcons4/depot.svg';
import dykkertjeneste from '../assets/mapIcons4/dykkertjeneste.svg';
import eksplosiver from '../assets/mapIcons4/eksplosiver.svg';
import fare from '../assets/mapIcons4/fare.svg';
import flom from '../assets/mapIcons4/flom.svg';
import forf from '../assets/mapIcons4/forf.svg';
import helikopterlandingsplass from '../assets/mapIcons4/helikopterlandingsplass.svg';
import hjertestarter from '../assets/mapIcons4/hjertestarter.svg';
import hundepatrulje from '../assets/mapIcons4/hundepatrulje.svg';
import informasjon from '../assets/mapIcons4/informasjon.svg';
import ko from '../assets/mapIcons4/ko.svg';
import lege from '../assets/mapIcons4/lege.svg';
import legehelikopter from '../assets/mapIcons4/legehelikopter.svg';
import mannoverbord from '../assets/mapIcons4/mannoverbord.svg';
import militaer from '../assets/mapIcons4/militaer.svg';
import moeteplass from '../assets/mapIcons4/moeteplass.svg';
import parkering from '../assets/mapIcons4/parkering.svg';
import politibaat from '../assets/mapIcons4/politibaat.svg';
import politibil from '../assets/mapIcons4/politibil.svg';
import politihelikopter from '../assets/mapIcons4/politihelikopter.svg';
import politimann from '../assets/mapIcons4/politimann.svg';
import radioaktiv from '../assets/mapIcons4/radioaktiv.svg';
import redningshund from '../assets/mapIcons4/redningshund.svg';
import redningsskoyte from '../assets/mapIcons4/redningsskoyte.svg';
import samleplass from '../assets/mapIcons4/samleplass.svg';
import sivilforsvar from '../assets/mapIcons4/sivilforsvar.svg';
import skred from '../assets/mapIcons4/skred.svg';
import snoscooter from '../assets/mapIcons4/snoscooter.svg';
import vegsperring from '../assets/mapIcons4/vegsperring.svg';
import venteplass from '../assets/mapIcons4/venteplass.svg';
import hendelse from '../assets/mapIcons4/hendelse.svg';
import hendelseifo from '../assets/mapIcons4/hen_inf.svg';
import targetTmp from '../assets/mapIcons4/target.svg';

const iconAdr = new L.Icon({
    iconUrl: adr,
    iconRetinaUrl: adr,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconAkp = new L.Icon({
    iconUrl: akp,
    iconRetinaUrl: akp,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconAmbulanse = new L.Icon({
    iconUrl: ambulanse,
    iconRetinaUrl: ambulanse,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconAndre = new L.Icon({
    iconUrl: andreressurser,
    iconRetinaUrl: andreressurser,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconBaal = new L.Icon({
    iconUrl: baal,
    iconRetinaUrl: baal,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconBrann = new L.Icon({
    iconUrl: brann,
    iconRetinaUrl: brann,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconBrannbaat = new L.Icon({
    iconUrl: brannbaat,
    iconRetinaUrl: brannbaat,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconBrannbil = new L.Icon({
    iconUrl: brannbil,
    iconRetinaUrl: brannbil,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconBrannhelikopter = new L.Icon({
    iconUrl: brannhelikopter,
    iconRetinaUrl: brannhelikopter,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconBrannmann = new L.Icon({
    iconUrl: brannmann,
    iconRetinaUrl: brannmann,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconDepot = new L.Icon({
    iconUrl: depot,
    iconRetinaUrl: depot,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconDykkertjeneste = new L.Icon({
    iconUrl: dykkertjeneste,
    iconRetinaUrl: dykkertjeneste,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconEksplosiver = new L.Icon({
    iconUrl: eksplosiver,
    iconRetinaUrl: eksplosiver,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconFare = new L.Icon({
    iconUrl: fare,
    iconRetinaUrl: fare,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconFlom = new L.Icon({
    iconUrl: flom,
    iconRetinaUrl: flom,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconForf = new L.Icon({
    iconUrl: forf,
    iconRetinaUrl: forf,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconHelikopterlandingsplass = new L.Icon({
    iconUrl: helikopterlandingsplass,
    iconRetinaUrl: helikopterlandingsplass,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconHjertestarter = new L.Icon({
    iconUrl: hjertestarter,
    iconRetinaUrl: hjertestarter,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconHundepatrulje = new L.Icon({
    iconUrl: hundepatrulje,
    iconRetinaUrl: hundepatrulje,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconInformasjon = new L.Icon({
    iconUrl: informasjon,
    iconRetinaUrl: informasjon,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconKo = new L.Icon({
    iconUrl: ko,
    iconRetinaUrl: ko,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconLege = new L.Icon({
    iconUrl: lege,
    iconRetinaUrl: lege,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconLegehelikopter = new L.Icon({
    iconUrl: legehelikopter,
    iconRetinaUrl: legehelikopter,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconMannoverbord = new L.Icon({
    iconUrl: mannoverbord,
    iconRetinaUrl: mannoverbord,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconMilitaer = new L.Icon({
    iconUrl: militaer,
    iconRetinaUrl: militaer,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconMoeteplass = new L.Icon({
    iconUrl: moeteplass,
    iconRetinaUrl: moeteplass,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconParkering = new L.Icon({
    iconUrl: parkering,
    iconRetinaUrl: parkering,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconPolitibaat = new L.Icon({
    iconUrl: politibaat,
    iconRetinaUrl: politibaat,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconPolitibil = new L.Icon({
    iconUrl: politibil,
    iconRetinaUrl: politibil,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconPolitihelikopter = new L.Icon({
    iconUrl: politihelikopter,
    iconRetinaUrl: politihelikopter,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconPolitimann = new L.Icon({
    iconUrl: politimann,
    iconRetinaUrl: politimann,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconRadioaktiv = new L.Icon({
    iconUrl: radioaktiv,
    iconRetinaUrl: radioaktiv,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconRedningshund = new L.Icon({
    iconUrl: redningshund,
    iconRetinaUrl: redningshund,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconRedningsskoyte = new L.Icon({
    iconUrl: redningsskoyte,
    iconRetinaUrl: redningsskoyte,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconSamleplass = new L.Icon({
    iconUrl: samleplass,
    iconRetinaUrl: samleplass,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconSivilforsvar = new L.Icon({
    iconUrl: sivilforsvar,
    iconRetinaUrl: sivilforsvar,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconSkred = new L.Icon({
    iconUrl: skred,
    iconRetinaUrl: skred,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconSnoscooter = new L.Icon({
    iconUrl: snoscooter,
    iconRetinaUrl: snoscooter,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconVegsperring = new L.Icon({
    iconUrl: vegsperring,
    iconRetinaUrl: vegsperring,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconVenteplass = new L.Icon({
    iconUrl: venteplass,
    iconRetinaUrl: venteplass,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconHendelse = new L.Icon({
    iconUrl: hendelse,
    iconRetinaUrl: hendelse,
    iconSize: new L.Point(70, 70),
    iconAnchor: [35, 70],
    popupAnchor: [0, -70],
});

const iconHendelsesInfo = new L.Icon({
    iconUrl: hendelseifo,
    iconRetinaUrl: hendelseifo,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
});

const iconTarget = new L.Icon({
    iconUrl: targetTmp,
    iconRetinaUrl: targetTmp,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 25],
    popupAnchor: [0, -50],
});

export {
    iconAdr,
    iconAkp,
    iconAmbulanse,
    iconAndre,
    iconBaal,
    iconBrann,
    iconBrannbaat,
    iconBrannbil,
    iconBrannhelikopter,
    iconBrannmann,
    iconDepot,
    iconDykkertjeneste,
    iconEksplosiver,
    iconFare,
    iconFlom,
    iconForf,
    iconHelikopterlandingsplass,
    iconHjertestarter,
    iconHundepatrulje,
    iconInformasjon,
    iconKo,
    iconLege,
    iconLegehelikopter,
    iconMannoverbord,
    iconMilitaer,
    iconMoeteplass,
    iconParkering,
    iconPolitibaat,
    iconPolitibil,
    iconPolitihelikopter,
    iconPolitimann,
    iconRadioaktiv,
    iconRedningsskoyte,
    iconRedningshund,
    iconSamleplass,
    iconSivilforsvar,
    iconSkred,
    iconSnoscooter,
    iconVegsperring,
    iconVenteplass,
    iconHendelse,
    iconHendelsesInfo,
    iconTarget,
};
