import {
    Divider,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { NewReleases as NewReleasesIcon } from '@material-ui/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';

const AksjonsListeElm = (props) => {
    const listitem = props.listitem;
    const incLink = `/aksjon/${listitem.id}`;
    return (
        <>
            <ListItem key={1} button component={Link} to={incLink}>
                <ListItemAvatar>
                    <NewReleasesIcon />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <b>
                            {listitem.title +
                                ', ' +
                                moment
                                    .unix(listitem.started.seconds)
                                    .format('DD.MM-YYYY[, kl. ]HH:mm:ss')}
                        </b>
                    }
                    secondary={
                        <Typography variant='body2'>
                            Aksjon opprettet av: {listitem.ownerName}
                            <br />
                            Siste oppdatering:{' '}
                            {moment
                                .unix(listitem.lastUpdate.seconds)
                                .format('DD.MM-YYYY[, kl. ]HH:mm:ss')}
                        </Typography>
                    }
                />
            </ListItem>
            <Divider />
        </>
    );
};

export default AksjonsListeElm;
