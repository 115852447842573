import { BrowserRouter as Router } from 'react-router-dom';
import { SwipeableDrawer } from '@material-ui/core';
import Header from './components/Header';
import PageRouter from './services/PageRouter';
import { useState } from 'react';
import Menu from './components/Menu';

function App() {
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <Router>
            <Header menupOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <PageRouter />
            <SwipeableDrawer
                anchor='left'
                open={menuOpen}
                onOpen={() => {
                    setMenuOpen(true);
                }}
                onClose={() => {
                    setMenuOpen(false);
                }}
            >
                <Menu setMenuOpen={setMenuOpen} />
            </SwipeableDrawer>
        </Router>
    );
}

export default App;
