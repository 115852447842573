import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';
import moment from 'moment';

export default function IncidentInfo(props) {
    return (
        <Dialog
            open={props.toggleInfo}
            onClose={() => {
                props.setToggleInfo(false);
            }}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle>Hendelsinformasjon:</DialogTitle>
            {props.incident && (
                <DialogContent>
                    <Button
                        variant='outlined'
                        style={{ marginBottom: 5 }}
                        onClick={() => {
                            props.setPosition(props.incident.position);
                            props.setToggleInfo(false);
                        }}
                    >
                        Zoom til aksjon
                    </Button>
                    <Typography variant='body1'>
                        <strong>Hendelse: </strong>
                        {props.incident.title}
                    </Typography>
                    <Typography variant='body1'>
                        <strong>Opprettet av: </strong>
                        {props.incident.ownerName}
                    </Typography>
                    <Typography variant='body1'>
                        <strong>Opprettet: </strong>
                        {moment
                            .unix(props.incident.started.seconds)
                            .format('DD.MM-YYYY[, kl. ]HH:mm:ss')}
                    </Typography>
                    <Typography variant='body1'>
                        <strong>Hendelsessted: </strong>
                        {props.incident.location}
                    </Typography>
                    <Divider />
                    <Typography variant='body1'>
                        <strong>Beskrivelse:</strong>
                    </Typography>
                    <Typography variant='body2'>
                        {props.incident.description}
                    </Typography>
                    {props.currentUser.uid === props.incident.owner &&
                        !props.toggleEnd && (
                            <Button
                                fullWidth
                                color='secondary'
                                variant='outlined'
                                onClick={() => {
                                    props.setToggleEnd(true);
                                }}
                            >
                                Avslutt hendelse
                            </Button>
                        )}
                    {props.toggleEnd && (
                        <>
                            <Divider />
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant='h6'>
                                        Vil du virkelig avslutte hendelse?
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        color='primary'
                                        variant='contained'
                                        onClick={() => {
                                            props.setToggleEnd(false);
                                            props.setToggleInfo(false);
                                            props.handleCloseIncident();
                                        }}
                                    >
                                        Ja
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        color='secondary'
                                        variant='contained'
                                        onClick={() => {
                                            props.setToggleEnd(false);
                                        }}
                                    >
                                        Nei
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </DialogContent>
            )}
        </Dialog>
    );
}
