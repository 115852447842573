import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Typography,
} from '@material-ui/core';
import moment from 'moment';
export default function PoiInfo(props) {
    if (props.activePoi) {
        if (!props.activePoi.iconType) {
            if (props.activePoi.type === 2) {
                props.activePoi.iconType = 'Polygon';
            } else if (props.activePoi.type === 3) {
                props.activePoi.iconType = 'Polyline';
            } else {
                props.activePoi.iconType = 'Generell';
            }
        }
    }
    return (
        <>
            {props.activePoi && (
                <Dialog
                    open={props.togglePoi}
                    onClose={() => {
                        props.setTogglePoi(false);
                        props.setActivePoi(null);
                        props.setTogglePoi(false);
                    }}
                    fullWidth
                    maxWidth='md'
                >
                    <DialogTitle>{props.activePoi.iconType}:</DialogTitle>
                    <DialogContent>
                        <Typography variant='h6'>
                            Opprettet av: {props.activePoi.authorName}
                        </Typography>
                        <Typography variant='body1'>
                            <strong>Opprettet:</strong>
                            {props.activePoi.timestamp &&
                                moment
                                    .unix(props.activePoi.timestamp.seconds)
                                    .format('DD.MM-YYYY[, kl. ]HH:mm:ss')}
                        </Typography>
                        <Divider />
                        <Typography variant='body1'>
                            <strong>Info:</strong>
                        </Typography>
                        <Typography variant='body2'>
                            {props.activePoi.info}
                        </Typography>
                        {(props.currentUser.uid === props.activePoi.authorid ||
                            props.currentUser.uid === props.incident.owner) && (
                            <Button
                                fullWidth
                                onClick={() => {
                                    props.handleDeleteMapLoc(
                                        props.activePoi.mapLocId
                                    );
                                }}
                                color='secondary'
                                variant='outlined'
                            >
                                Slett
                            </Button>
                        )}
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
}
