import { Switch, Route } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Incident from '../pages/Incident';
import IncidentV2 from '../pages/IncidentV2';
import IncidentV3 from '../pages/IncidentV3';
import UserSettings from '../pages/UserSettings';
import PageNotFound from '../pages/PageNotFound';
import Login from '../pages/Login';
import Signup from '../pages/Signup';
import PrivateRoute from '../components/PrivateRoute';
import ForgotPassword from '../pages/ForgotPassword';
import CreateProfile from '../pages/CreateProfile';
import CreateNewIncident from '../pages/CreateNewIncident';
import ChangeLog from '../pages/ChangeLog';
import Historic from '../pages/Historic';
import HistoricIncident from '../pages/HistoricIncident';
import Chat from '../pages/Chat';
const PageRouter = () => {
    return (
        <Switch>
            <PrivateRoute path='/' exact component={Dashboard} />
            <PrivateRoute path='/aksjon/:id' component={IncidentV3} />
            <PrivateRoute path='/settings' component={UserSettings} />
            <PrivateRoute
                path='/opprett-aksjon'
                component={CreateNewIncident}
            />
            <Route path='/innlogging' component={Login} />
            <Route path='/registrering' component={Signup} />
            <Route path='/opprett-profil' component={CreateProfile} />
            <Route path='/glemt-passord' component={ForgotPassword} />
            <PrivateRoute path='/endring' component={ChangeLog} />
            <PrivateRoute
                path='/historikk/:incidentId'
                component={HistoricIncident}
            />
            <PrivateRoute path='/historikk/' component={Historic} />
            <Route path='/chat' component={Chat} />
            <Route component={PageNotFound} />
        </Switch>
    );
};

export default PageRouter;
