import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    TextField,
} from '@material-ui/core';
import { useState } from 'react';
import { Alert, Autocomplete } from '@material-ui/lab';
import firebase from 'firebase/app';
import { useHistory, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../services/firebase';
import {
    MapContainer,
    Marker,
    TileLayer,
    useMapEvents,
    useMap,
} from 'react-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

function MyComponent(props) {
    const map = useMap();
    useMapEvents({
        click: (e) => {
            props.handleMapClick(e);
        },
    });
    if (props.position) {
        map.flyTo(props.position, 12);
    }
    return null;
}

export default function CreateNewIncident() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [title, setTitle] = useState('');
    const [location, setLocation] = useState(null);
    const [description, setDescription] = useState('');
    const [position, setPosition] = useState(null);
    const [isTest, setIsTest] = useState(true);
    const [locationList, setLocationList] = useState([]);
    const { currentUser } = useAuth();
    const geoSearchProvider = new OpenStreetMapProvider({
        params: {
            'accept-language': 'no',
            countrycodes: 'no',
        },
    });
    const history = useHistory();
    const handleMapClick = (evt) => {
        setPosition([evt.latlng.lat, evt.latlng.lng]);
    };
    const handleSubmit = async (evt) => {
        setError('');
        if (title.length < 3) {
            return setError('Tittel må være minst 3 tegn...');
        }
        if (description.length < 5) {
            return setError('Beskrivelse må være minst 5 tegn...');
        }
        if (!position) {
            return setError('Du må minst ha posisjon markert i kart...');
        }
        try {
            if (!error) setLoading(true);
            let startLocation =
                'Lokasjon ikke beskrevet, se markering i kart...';
            if (location && location.label) {
                startLocation = location.label;
            }
            const aksjonRef = await db.collection('incidents').add({
                title: title,
                location: startLocation,
                position: position,
                description: description,
                owner: currentUser.uid,
                ownerName: currentUser.displayName,
                started: firebase.firestore.FieldValue.serverTimestamp(),
                lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
                ended: false,
            });
            await db
                .collection('incidents')
                .doc(aksjonRef.id)
                .collection('chat')
                .add({
                    authorid: currentUser.uid,
                    isSystemMsg: true,
                    authorName: 'SQUARE',
                    org: 'SYSTEM',
                    message: `${currentUser.displayName} opprettet en ny hendelse med tittel: "${title}", Beskrivelse: "${description}"`,
                    timestamp: firebase.firestore.Timestamp.now(),
                });
            history.push(`/aksjon/${aksjonRef.id}`);
        } catch (err) {
            setError('Klarte ikke å opprette ny aksjon');
            console.log(err);
        }
        setLoading(false);
    };
    const handleGeoSearch = async (evt) => {
        if (evt.target.value.length > 3) {
            setLocationList(
                await geoSearchProvider.search({ query: evt.target.value })
            );
        } else {
            setLocationList([]);
        }
    };

    return (
        <Container maxWidth='md'>
            <Card style={{ marginTop: 80 }}>
                <CardHeader title='Opprett ny hendelse' />
                <CardContent>
                    {error && (
                        <Alert severity='error' style={{ marginBottom: 20 }}>
                            {error}
                        </Alert>
                    )}
                    <TextField
                        fullWidth
                        variant='outlined'
                        label='Tittel(minst 3 tegn)'
                        value={title}
                        onChange={(evt) => {
                            setTitle(evt.target.value);
                        }}
                        style={{ marginBottom: 20 }}
                    />
                    <TextField
                        fullWidth
                        variant='outlined'
                        label='Kort beskrivelse(minst 5 tegn)'
                        multiline
                        rows={8}
                        value={description}
                        onChange={(evt) => setDescription(evt.target.value)}
                        style={{ marginBottom: 20 }}
                    />
                    <Autocomplete
                        options={locationList}
                        getOptionLabel={(option) => option.label}
                        value={location}
                        onChange={(evt, newValue) => {
                            setLocation(newValue);
                            setLocationList([]);
                            if (newValue) {
                                setPosition([newValue.y, newValue.x]);
                            } else {
                                setPosition(null);
                            }
                        }}
                        fullWidth
                        style={{ marginBottom: 20 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Lokasjon (Sted/Kommune)'
                                onChange={(evt) => {
                                    handleGeoSearch(evt);
                                }}
                                variant='outlined'
                            />
                        )}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            Sett eller flytt markering i kart:
                        </Grid>
                        <Grid item xs={12}>
                            <MapContainer
                                center={[58.5, 7.9]}
                                zoom={8}
                                scrollWheelZoom={true}
                                style={{ height: '35vh' }}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                />
                                {position && <Marker position={position} />}
                                <MyComponent
                                    handleMapClick={handleMapClick}
                                    position={position}
                                />
                            </MapContainer>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant='contained'
                                fullWidth
                                color='primary'
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                Start hendelse
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant='contained'
                                fullWidth
                                color='secondary'
                                component={Link}
                                to='/'
                            >
                                Avbryt
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
}
