import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function ForgotPassword() {
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const { forgotPassword } = useAuth();

    const handleForgotPassword = async () => {
        console.log('resetter...');
        if (email.length < 3) {
            return setError('Email kan ikke være under 3 tegn...');
        }
        try {
            setMessage('');
            setError('');
            setLoading(true);
            await forgotPassword(email);
            setMessage(
                'Sjekk epost for instruksjon på hvordan du resetter passordet...'
            );
        } catch {
            setError('Klarte ikke å nullstille passord');
        }
        setLoading(false);
    };
    return (
        <Container maxWidth='sm'>
            <Card style={{ marginTop: 80 }}>
                <CardHeader title='Nullstill passord' />
                <CardContent>
                    {message && (
                        <Alert severity='success' style={{ marginBottom: 20 }}>
                            {message}
                        </Alert>
                    )}
                    {error && (
                        <Alert severity='error' style={{ marginBottom: 20 }}>
                            {error}
                        </Alert>
                    )}
                    <TextField
                        variant='outlined'
                        label='Email'
                        type='email'
                        fullWidth
                        value={email}
                        onChange={(evt) => {
                            setEmail(evt.target.value);
                        }}
                        style={{ marginBottom: 20 }}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                color='primary'
                                variant='contained'
                                fullWidth
                                onClick={handleForgotPassword}
                            >
                                Nullstill passord
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                color='secondary'
                                variant='contained'
                                fullWidth
                                disabled={loading}
                                component={Link}
                                to='/innlogging'
                            >
                                Avbryt
                            </Button>
                        </Grid>
                    </Grid>
                    <Typography variant='body1' style={{ marginTop: 20 }}>
                        Har du ikke bruker?
                    </Typography>
                    <Typography variant='body1'>
                        <Link
                            to='/registrering'
                            style={{
                                textDecoration: 'none',
                                color: 'black',
                                fontWeight: 'bold',
                            }}
                        >
                            Trykk her for å opprette en...
                        </Link>
                    </Typography>
                </CardContent>
            </Card>
        </Container>
    );
}
