import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    TextField,
    Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const iconTypeList = [
    'Farlig gods',
    'Ambulansekontrollpunkt',
    'Ambulanse',
    'Andre ressurser',
    'Bål',
    'Brann',
    'Brannbåt',
    'Brannbil',
    'Brannhelikopter',
    'Brannmann',
    'Depot',
    'Dykkertjeneste',
    'Eksplosiver',
    'Fare',
    'Flom',
    'FORF',
    'Helikopterlandingsplass',
    'Hjertestarter',
    'Hundepatrulje',
    'Informasjon',
    'KO',
    'Lege',
    'Luftambulanse',
    'Mann over bord',
    'Militær',
    'Møteplass',
    'Parkering',
    'Politibåt',
    'Politibil',
    'Politihelikopter',
    'Politimann',
    'Radioaktiv',
    'Redningshund',
    'Redningsskøyte',
    'Samleplass',
    'Sivilforsvar',
    'Skred',
    'Snøscooter',
    'Vegsperring',
    'Venteplass',
];
export default function CreateMapFeatureDialog(props) {
    if (props.createMarker) {
        return (
            <Box
                style={{
                    zIndex: 800,
                    position: 'absolute',
                    bottom: 20,
                    minWidth: 400,
                    padding: 10,
                    maxHeight: '45%',
                }}
            >
                <Card>
                    <CardHeader title='Opprett POI:' />
                    <CardContent>
                        <Typography variant='h5'>Nytt POI:</Typography>
                        <Typography variant='body1'>
                            Skriv inn info og klikk i kartet der markøren skal
                            være
                        </Typography>
                        <TextField
                            multiline
                            rows={4}
                            fullWidth
                            label='Info'
                            variant='outlined'
                            style={{ marginTop: 10 }}
                            value={props.poiInfo}
                            onChange={(evt) => {
                                props.setPoiInfo(evt.target.value);
                            }}
                        />
                        <Autocomplete
                            options={iconTypeList}
                            getOptionLabel={(option) => option}
                            value={props.poiType}
                            onChange={(evt, value) => {
                                props.setPoiType(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Velg type POI'
                                    fullWidth
                                    variant='outlined'
                                    style={{ marginTop: 20 }}
                                />
                            )}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    style={{ marginTop: 10 }}
                                    disabled={props.isSubmitting}
                                    onClick={props.handleSavePOI}
                                >
                                    Lagre
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    style={{ marginTop: 10 }}
                                    disabled={props.isSubmitting}
                                    onClick={() => {
                                        props.setCreateMarker(false);
                                        props.setPoiTmpData('');
                                    }}
                                >
                                    Avbryt
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        );
    }
    if (props.createPoly) {
        return (
            <Box
                style={{
                    zIndex: 800,
                    position: 'absolute',
                    bottom: 10,
                    minWidth: 400,
                    padding: 10,
                    maxHeight: '45%',
                }}
            >
                <Card>
                    <CardHeader title='Opprett polygon:' />
                    <CardContent>
                        <Typography variant='body1'>
                            Skriv inn info og klikk i kartet punktene du vil ha.
                            Må ha mer enn 2 punkt...
                        </Typography>
                        <TextField
                            multiline
                            rows={4}
                            fullWidth
                            label='Info'
                            variant='outlined'
                            style={{ marginTop: 10 }}
                            value={props.polyInfo}
                            onChange={(evt) => {
                                props.setPolyInfo(evt.target.value);
                            }}
                        />
                        <FormControl
                            variant='outlined'
                            fullWidth
                            style={{ marginTop: 20 }}
                        >
                            <InputLabel id='colorPickerLabel'>
                                Velg farge
                            </InputLabel>
                            <Select
                                labelId='colorPickerLabel'
                                label='Velg farge'
                                value={props.polyColor}
                                onChange={(evt) => {
                                    props.setPolyColor(evt.target.value);
                                }}
                            >
                                <MenuItem value='red'>Rød Sone</MenuItem>
                                <MenuItem value='yellow'>Gul Sone</MenuItem>
                                <MenuItem value='green'>Grønn Sone</MenuItem>
                                <MenuItem value='white'>Annet (Hvit)</MenuItem>
                            </Select>
                        </FormControl>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    style={{ marginTop: 10 }}
                                    disabled={props.isSubmitting}
                                    onClick={() => {
                                        props.handleSavePoly();
                                    }}
                                >
                                    Lagre
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    style={{ marginTop: 10 }}
                                    disabled={props.isSubmitting}
                                    onClick={() => {
                                        props.setCreatePoly(false);
                                        props.setPolyData([]);
                                        props.setPolyColor('white');
                                    }}
                                >
                                    Avbryt
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        );
    }
    if (props.createPolyLine) {
        return (
            <Box
                style={{
                    zIndex: 800,
                    position: 'absolute',
                    bottom: 10,
                    minWidth: 400,
                    padding: 10,
                    maxHeight: '45%',
                }}
            >
                <Card>
                    <CardHeader title='Opprett Linje:' />
                    <CardContent>
                        <Typography variant='body1'>
                            Skriv inn info og klikk i kartet punktene du vil ha
                        </Typography>
                        <TextField
                            multiline
                            rows={4}
                            fullWidth
                            label='Info'
                            variant='outlined'
                            style={{ marginTop: 10, marginBottom: 10 }}
                            value={props.polyLineInfo}
                            onChange={(evt) => {
                                props.setPolyLineInfo(evt.target.value);
                            }}
                        />
                        <Typography id='polyLineWidth-label' gutterBottom>
                            Linjebredde
                        </Typography>
                        <Slider
                            value={props.polyLineWidth}
                            onChange={(evt, value) => {
                                props.setPolyLineWidth(value);
                            }}
                            getAriaValueText={props.polyLineWith}
                            step={2}
                            aria-labelledby='polyLineWidth-label'
                            valueLabelDisplay='auto'
                            marks
                            min={4}
                            max={12}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    style={{ marginTop: 10 }}
                                    disabled={props.isSubmitting}
                                    onClick={() => {
                                        props.handleSavePolyLine();
                                    }}
                                >
                                    Lagre
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    style={{ marginTop: 10 }}
                                    disabled={props.isSubmitting}
                                    onClick={() => {
                                        props.setCreatePolyLine(false);
                                        props.setPolyLineData([]);
                                        props.setPolyLineWidth(8);
                                    }}
                                >
                                    Avbryt
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        );
    }
    return null;
}
