import { Card, CardContent, CardHeader, List } from '@material-ui/core';
import AksjonsListeElm from './AksjonsListeElm';

const AksjonsListe = (props) => {
    return (
        <Card style={{ height: '100%' }}>
            <CardHeader title={props.title} />
            <CardContent>
                <List>
                    {props.incidentList.map((listitem) => {
                        return (
                            <AksjonsListeElm
                                listitem={listitem}
                                key={listitem.started.seconds}
                                setSelectedInc={props.setSelectedInc}
                            />
                        );
                    })}
                </List>
            </CardContent>
        </Card>
    );
};

export default AksjonsListe;
