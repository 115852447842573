import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import {
    Home as HomeIcon,
    VpnKey as VpnKeyIcon,
    Settings as SettingsIcon,
    Build as BuildIcon,
    History as HistoryIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';

const Menu = (props) => {
    return (
        <List component='nav'>
            <ListItem
                button
                component={Link}
                to='/'
                onClick={() => {
                    props.setMenuOpen(false);
                }}
            >
                <ListItemIcon>
                    <HomeIcon />
                </ListItemIcon>
                <ListItemText primary='Hjem' />
            </ListItem>
            <ListItem
                button
                component={Link}
                to='/historikk'
                onClick={() => {
                    props.setMenuOpen(false);
                }}
            >
                <ListItemIcon>
                    <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary='Historikk' />
            </ListItem>
            <ListItem
                button
                component={Link}
                to='/settings'
                onClick={() => {
                    props.setMenuOpen(false);
                }}
            >
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary='Settings' />
            </ListItem>
            <ListItem
                button
                component={Link}
                to='/endring'
                onClick={() => {
                    props.setMenuOpen(false);
                }}
            >
                <ListItemIcon>
                    <BuildIcon />
                </ListItemIcon>
                <ListItemText primary='Endringslogg' />
            </ListItem>
            <Divider />
            <ListItem
                button
                component={Link}
                to='/innlogging'
                onClick={() => {
                    props.setMenuOpen(false);
                }}
            >
                <ListItemIcon>
                    <VpnKeyIcon />
                </ListItemIcon>
                <ListItemText primary='Logg inn/ut' />
            </ListItem>
        </List>
    );
};

export default Menu;
