import { useState, useEffect } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import AksjonsListe from '../components/aksjonsliste/AksjonsListe';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../services/firebase';

const Dashboard = () => {
    const { currentUser } = useAuth();
    const [newIncidentList, setNewIncidentList] = useState([]);
    const [memberIncidentList, setMemberIncidentList] = useState([]);

    const filterNewIncidentList = async (list) => {
        let tempList = list;
        await tempList.sort((a, b) => {
            return b.started - a.started;
        });
        setMemberIncidentList(
            tempList.filter((item) => {
                return item.owner === currentUser.uid;
            })
        );
    };
    const filterMemberIncidentList = async (list) => {
        let tempList = list;
        await tempList.sort((a, b) => {
            return b.started - a.started;
        });
        setNewIncidentList(
            tempList.filter((item) => {
                return item.owner !== currentUser.uid;
            })
        );
    };

    useEffect(() => {
        const unsuscribe = db
            .collection('incidents')
            .where('ended', '==', false)
            .onSnapshot((snapshot) => {
                let tempList = [];
                snapshot.forEach((doc) => {
                    let obj = doc.data();
                    obj.id = doc.id;
                    tempList.push(obj);
                });
                filterNewIncidentList(tempList);
                filterMemberIncidentList(tempList);
            });
        return () => {
            unsuscribe();
        };
    }, []);

    return (
        <Container maxWidth={false} style={{ paddingTop: 90 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='body1'>
                        <strong>SQUARE (Situational Quality Assurance)</strong>{' '}
                        er et verktøy for å hjelpe nødetatene og eventuelle
                        samarbeidspartnere, å oppnå en felles
                        situasjonsforståelse i en hendelse.
                    </Typography>
                    <Typography variant='body1'>
                        Verktøyet er pr nå en tidlig alfa fase, og{' '}
                        <strong>
                            <u>MÅ IKKE</u>
                        </strong>{' '}
                        brukes i skarpe aksjoner.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant='contained'
                        fullWidth
                        color='primary'
                        component={Link}
                        to='/opprett-aksjon'
                    >
                        Opprett ny hendelse
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <AksjonsListe
                        title={'Mine aksjoner:'}
                        showPending={true}
                        showMembers={true}
                        showLegend={false}
                        incidentList={memberIncidentList}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AksjonsListe
                        title={'Andre aktive aksjoner:'}
                        showPending={false}
                        showMembers={true}
                        showLegend={true}
                        incidentList={newIncidentList}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Dashboard;
