import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import moment from 'moment';
import 'moment/locale/nb';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { blueGrey, red } from '@material-ui/core/colors';
import { ToolbarProvider } from './contexts/ToolbarContext';

moment.locale('nb');
const theme = createMuiTheme({
    palette: {
        primary: {
            main: blueGrey[800],
        },
        secondary: {
            main: red[900],
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <ToolbarProvider>
                    <CssBaseline />
                    <App />
                </ToolbarProvider>
            </AuthProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
