import { useState } from 'react';
import LoginCard from '../components/LoginCard';
import { useAuth } from '../contexts/AuthContext';
import { useHistory } from 'react-router-dom';

const Login = () => {
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const { login, logout, currentUser } = useAuth();
    const history = useHistory();

    const handleLogin = async () => {
        if (email.length < 3) {
            return setError('Email kan ikke være under 3 tegn...');
        }
        if (password.length < 3) {
            return setError('Passord kan ikke være under 3 tegn...');
        }
        try {
            setError('');
            setLoading(true);
            await login(email, password);
            history.push('/');
        } catch {
            setError('Klarte ikke å logge inn...');
        }
        setLoading(false);
    };
    const handleLogout = async () => {
        try {
            setError('');
            setLoading(true);
            await logout();
        } catch {
            setError('Klarte ikke å logge ut...');
        }
        setLoading(false);
    };
    return (
        <LoginCard
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            handleLogin={handleLogin}
            loading={loading}
            error={error}
            currentUser={currentUser}
            handleLogout={handleLogout}
        />
    );
};

export default Login;
