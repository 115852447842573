import {
    Card,
    CardContent,
    CardHeader,
    Container,
    Divider,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { db } from '../services/firebase';
import moment from 'moment';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';

export default function ChangeLog() {
    const changeLogRef = db.collection('changeLog').orderBy('date', 'desc');
    const [changeLog, changeLoading, changeError] = useCollectionDataOnce(
        changeLogRef
    );
    return (
        <Container maxWidth='lg'>
            <Card style={{ marginTop: 80 }}>
                <CardHeader title='Endringslogg' />
                {changeLog && (
                    <CardContent>
                        <List>
                            {changeLog.map((elm) => {
                                return (
                                    <>
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    <>
                                                        <strong>
                                                            {moment
                                                                .unix(
                                                                    elm.date
                                                                        .seconds
                                                                )
                                                                .format(
                                                                    'DD.MM-YYYY'
                                                                )}
                                                            {': '}
                                                        </strong>

                                                        {elm.title}
                                                    </>
                                                }
                                                secondary={
                                                    <ul>
                                                        {elm.new &&
                                                            elm.new.map(
                                                                (msgItem) => {
                                                                    return (
                                                                        <li>
                                                                            <strong>
                                                                                NYTT:
                                                                            </strong>{' '}
                                                                            {
                                                                                msgItem
                                                                            }
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                        {elm.bugfix &&
                                                            elm.bugfix.map(
                                                                (msgItem) => {
                                                                    return (
                                                                        <li>
                                                                            <strong>
                                                                                BUGFIX:
                                                                            </strong>{' '}
                                                                            {
                                                                                msgItem
                                                                            }
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                        {elm.change &&
                                                            elm.change.map(
                                                                (msgItem) => {
                                                                    return (
                                                                        <li>
                                                                            <strong>
                                                                                ENDRING:
                                                                            </strong>{' '}
                                                                            {
                                                                                msgItem
                                                                            }
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                    </ul>
                                                }
                                            />
                                        </ListItem>
                                        <Divider />
                                    </>
                                );
                            })}
                        </List>
                    </CardContent>
                )}
            </Card>
        </Container>
    );
}
