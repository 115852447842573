import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';

export default function GeoSearch(props) {
    return (
        <Autocomplete
            options={props.locationList}
            getOptionLabel={(option) => option.label}
            value={props.location}
            onChange={(evt, newValue) => {
                props.setLocation(newValue);
                props.setLocationList([]);
                if (newValue) {
                    props.setPosition([newValue.y, newValue.x]);
                    props.setToggleSearch(false);
                } else {
                    props.setPosition(null);
                }
            }}
            fullWidth
            style={{ marginTop: 8, marginBottom: 5 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label='Søk på sted eller adresse...'
                    onChange={(evt) => {
                        props.handleGeoSearch(evt);
                    }}
                    variant='outlined'
                />
            )}
        />
    );
}
