import {
    AppBar,
    Badge,
    Hidden,
    IconButton,
    InputBase,
    ListItemIcon,
    Menu,
    MenuItem,
    TextField,
    Toolbar,
    Typography,
} from '@material-ui/core';
import {
    Menu as MenuIcon,
    Backspace as BackspaceIcon,
    Info as InfoIcon,
    Search as SearchIcon,
    LocationSearching as LocationSearchingIcon,
    Apps as AppsIcon,
    Chat as ChatIcon,
    LibraryAdd as LibraryAddIcon,
    ControlPoint as ControlPointIcon,
    Timeline as TimelineIcon,
    CropSquare as CropSquareIcon,
    MobileScreenShare as MobileScreenShareIcon,
    Map as MapIcon,
    ExitToApp as ExitToAppIcon,
    SpeakerNotes as SpeakerNotesIcon,
} from '@material-ui/icons';
import { Link, Route, Switch } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useToolbar } from '../contexts/ToolbarContext';
import logoImg from '../assets/images/logo.png';
import { useState } from 'react';

const Header = (props) => {
    //const { setShowIncidentInfo } = useAuth();
    const {
        toggleGeoLoc,
        setToggleGeoLoc,
        toggleSearch,
        setToggleSearch,
        toggleInfo,
        setToggleInfo,
        toggleTools,
        setToggleTools,
        toggleChat,
        setToggleChat,
        createMarker,
        setCreateMarker,
        createPoly,
        setCreatePoly,
        createPolyLine,
        setCreatePolyLine,
        createCircle,
        setCreateCircle,
        nUnreadChat,
    } = useToolbar();
    const [anchorEl, setAnchorEl] = useState(null);
    return (
        <AppBar position='fixed' style={{ flexGrow: 1 }}>
            <Toolbar>
                <IconButton
                    edge='start'
                    color='inherit'
                    onClick={() => {
                        props.setMenuOpen(!props.menuOpen);
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <img
                    src={logoImg}
                    alt='Logo'
                    style={{ marginLeft: 10, marginRight: 10, maxWidth: 40 }}
                />

                <Typography variant='h6' style={{ flexGrow: 1 }}>
                    <Hidden smDown>SQUARE</Hidden>
                </Typography>
                <Switch>
                    <Route path='/aksjon'>
                        <IconButton
                            disabled={toggleChat}
                            color='inherit'
                            onClick={() => {
                                setToggleSearch(!toggleSearch);
                                setToggleTools(false);
                            }}
                        >
                            <SearchIcon />
                        </IconButton>
                        <IconButton
                            color='inherit'
                            onClick={() => {
                                setToggleChat(!toggleChat);
                                setToggleSearch(false);
                                setCreateCircle(false);
                                setCreateMarker(false);
                                setCreatePoly(false);
                                setCreatePolyLine(false);
                            }}
                        >
                            <Badge badgeContent={nUnreadChat} color='secondary'>
                                {!toggleChat ? (
                                    <SpeakerNotesIcon />
                                ) : (
                                    <MapIcon />
                                )}
                            </Badge>
                        </IconButton>
                        <IconButton
                            color='inherit'
                            onClick={() => {
                                setToggleInfo(!toggleInfo);
                            }}
                        >
                            <InfoIcon />
                        </IconButton>
                        <IconButton
                            disabled={toggleChat}
                            color='inherit'
                            onClick={(evt) => {
                                setAnchorEl(evt.currentTarget);
                                setCreatePolyLine(false);
                                setCreatePoly(false);
                                setCreateMarker(false);
                            }}
                        >
                            <LibraryAddIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            style={{ marginTop: 40 }}
                        >
                            <Typography variant='h6' align='center'>
                                Kartobjekt:
                            </Typography>
                            <MenuItem
                                onClick={() => {
                                    setCreateMarker(true);
                                    setAnchorEl(null);
                                }}
                            >
                                <ListItemIcon>
                                    <ControlPointIcon fontSize='small' />
                                </ListItemIcon>
                                <Typography variant='inherit'>
                                    Opprett POI
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setCreatePoly(true);
                                    setAnchorEl(null);
                                }}
                            >
                                <ListItemIcon>
                                    <CropSquareIcon fontSize='small' />
                                </ListItemIcon>
                                <Typography variant='inherit'>
                                    Opprett Polygon
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setCreatePolyLine(true);
                                    setAnchorEl(null);
                                }}
                            >
                                <ListItemIcon>
                                    <TimelineIcon fontSize='small' />
                                </ListItemIcon>
                                <Typography variant='inherit'>
                                    Opprett Linje
                                </Typography>
                            </MenuItem>
                            {/* <MenuItem
                                onClick={() => {
                                    setAnchorEl(null);
                                }}
                            >
                                <ListItemIcon>
                                    <MobileScreenShareIcon fontSize='small' />
                                </ListItemIcon>
                                <Typography variant='inherit'>
                                    Generer HENSPE
                                </Typography>
                            </MenuItem> */}
                        </Menu>
                        <IconButton color='inherit' component={Link} to='/'>
                            <ExitToAppIcon />
                        </IconButton>
                    </Route>
                    <Route />
                </Switch>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
