import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';

const LoginCard = (props) => {
    return (
        <Container maxWidth='sm'>
            {props.currentUser ? (
                <Card style={{ marginTop: 80 }}>
                    <CardHeader title='Logg ut' />
                    <CardContent>
                        {props.error && (
                            <Alert
                                severity='error'
                                style={{ marginBottom: 20 }}
                            >
                                {props.error}
                            </Alert>
                        )}
                        <Button
                            color='secondary'
                            variant='contained'
                            fullWidth
                            onClick={props.handleLogout}
                        >
                            Logg ut
                        </Button>
                    </CardContent>
                </Card>
            ) : (
                <Card style={{ marginTop: 80 }}>
                    <CardHeader title='Logg inn' />
                    <CardContent>
                        {props.error && (
                            <Alert
                                severity='error'
                                style={{ marginBottom: 20 }}
                            >
                                {props.error}
                            </Alert>
                        )}
                        <TextField
                            variant='outlined'
                            label='Email'
                            type='email'
                            fullWidth
                            value={props.email}
                            onChange={(evt) => {
                                props.setEmail(evt.target.value);
                            }}
                            style={{ marginBottom: 20 }}
                        />
                        <TextField
                            variant='outlined'
                            label='Passord'
                            type='password'
                            value={props.password}
                            onChange={(evt) => {
                                props.setPassword(evt.target.value);
                            }}
                            fullWidth
                            style={{ marginBottom: 20 }}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    fullWidth
                                    onClick={props.handleLogin}
                                >
                                    Logg inn
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    color='secondary'
                                    variant='contained'
                                    fullWidth
                                    component={Link}
                                    to='/glemt-passord'
                                >
                                    Glemt passord
                                </Button>
                            </Grid>
                        </Grid>
                        <Typography variant='body1' style={{ marginTop: 20 }}>
                            Har du ikke bruker?
                        </Typography>
                        <Typography variant='body1'>
                            <Link
                                to='/registrering'
                                style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    fontWeight: 'bold',
                                }}
                            >
                                Trykk her for å opprette en...
                            </Link>
                        </Typography>
                    </CardContent>
                </Card>
            )}
        </Container>
    );
};

export default LoginCard;
