import { useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const CreateProfile = () => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [branch, setBranch] = useState('');

    const handleSubmit = async () => {};
    return (
        <Container maxWidth='sm'>
            <Card style={{ marginTop: 80 }}>
                <CardHeader title='Opprett profildata' />
                <CardContent>
                    {error && (
                        <Alert severity='error' style={{ marginBottom: 20 }}>
                            {error}
                        </Alert>
                    )}
                    <TextField
                        variant='outlined'
                        label='Visningsnavn(til chat)'
                        type='text'
                        fullWidth
                        value={name}
                        onChange={(evt) => {
                            setName(evt.target.value);
                        }}
                        style={{ marginBottom: 20 }}
                    />
                    <FormControl
                        variant='outlined'
                        fullWidth
                        style={{ marginBottom: 20 }}
                    >
                        <InputLabel id='etat-label'>Etat:</InputLabel>
                        <Select
                            labelId='etat-label'
                            value={branch}
                            onChange={(evt) => {
                                setBranch(evt.target.value);
                            }}
                            label='Etat:'
                        >
                            <MenuItem value=''>
                                <em>Ingen</em>
                            </MenuItem>
                            <MenuItem value={'Politi'}>Politi</MenuItem>
                            <MenuItem value={'Helse'}>Helse</MenuItem>
                            <MenuItem value={'Brann'}>Brann</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        color='primary'
                        variant='contained'
                        fullWidth
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        Lagre profildata
                    </Button>
                </CardContent>
            </Card>
        </Container>
    );
};

export default CreateProfile;
