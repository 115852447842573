import {
    Button,
    Container,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';
import {
    Page,
    Text,
    Document,
    PDFDownloadLink,
    View,
    PDFViewer,
} from '@react-pdf/renderer';
import { useMemo } from 'react';
import {
    useCollectionDataOnce,
    useDocumentDataOnce,
} from 'react-firebase-hooks/firestore';
import { useParams, Link } from 'react-router-dom';
import { db } from '../services/firebase';
import moment from 'moment';

const PDFReport = (props) => {
    return (
        <Document>
            {props.incident && (
                <Page
                    style={{
                        paddingTop: 35,
                        paddingBottom: 65,
                        paddingHorizontal: 35,
                    }}
                >
                    <Text
                        style={{
                            fontSize: 24,
                            textAlign: 'center',
                            marginBottom: 10,
                        }}
                    >
                        {props.incident.title}
                    </Text>
                    <View style={{ borderBottomWidth: 4 }} />
                    <Text
                        style={{
                            fontSize: 16,
                            textAlign: 'left',
                            borderBottomStyle: 'dashed',
                            borderBottomWidth: 2,
                            paddingBottom: 5,
                            paddingTop: 10,
                            marginBottom: 5,
                        }}
                    >
                        Startet av: {props.incident.ownerName}
                    </Text>
                    <Text
                        style={{
                            fontSize: 16,
                            textAlign: 'left',
                            marginBottom: 5,
                        }}
                    >
                        Startet:{' '}
                        {moment
                            .unix(props.incident.started.seconds)
                            .format('DD.MM-YYYY[, kl. ]HH:mm:ss')}
                    </Text>
                    <Text
                        style={{
                            fontSize: 16,
                            paddingBottom: 5,
                            textAlign: 'left',
                            marginBottom: 10,
                            borderBottomStyle: 'dashed',
                            borderBottomWidth: 2,
                        }}
                    >
                        Avsluttet:{' '}
                        {moment
                            .unix(props.incident.lastUpdate.seconds)
                            .format('DD.MM-YYYY[, kl. ]HH:mm:ss')}
                    </Text>
                    <Text
                        style={{
                            fontSize: 16,
                            textAlign: 'left',
                            paddingBottom: 5,
                            marginBottom: 10,
                            borderBottomStyle: 'dashed',
                            borderBottomWidth: 2,
                        }}
                    >
                        Lokasjon: {props.incident.location}
                    </Text>
                    <Text style={{ fontSize: 20, fontWeight: 'ultrabold' }}>
                        Beskrivelse:
                    </Text>
                    <Text
                        style={{
                            fontSize: 16,
                            margin: 12,
                        }}
                    >
                        {props.incident.description}
                    </Text>

                    <Text
                        style={{
                            position: 'absolute',
                            fontSize: 12,
                            bottom: 30,
                            left: 0,
                            right: 0,
                            textAlign: 'center',
                            color: 'grey',
                        }}
                        render={({ pageNumber, totalPages }) =>
                            `${pageNumber} / ${totalPages}`
                        }
                        fixed
                    />
                </Page>
            )}
            {props.chats && (
                <Page
                    style={{
                        paddingTop: 35,
                        paddingBottom: 65,
                        paddingHorizontal: 35,
                    }}
                >
                    <Text
                        style={{
                            fontSize: 24,
                            textAlign: 'center',
                            marginBottom: 10,
                        }}
                    >
                        Chat logg:
                    </Text>
                    <View style={{ borderBottomWidth: 4 }} />
                    {props.chats &&
                        props.chats.map((chat, index) => {
                            return (
                                <View
                                    key={index}
                                    style={{
                                        padding: 10,
                                        margin: 5,
                                        borderWidth: 2,
                                    }}
                                >
                                    <Text
                                        style={{
                                            borderBottomStyle: 'dashed',
                                            borderBottomWidth: 2,
                                            fontSize: 16,
                                            paddingBottom: 10,
                                        }}
                                    >
                                        {chat.authorName},{' '}
                                        {moment
                                            .unix(chat.timestamp.seconds)
                                            .format(
                                                'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                            )}
                                    </Text>
                                    <Text
                                        style={{ marginTop: 10, fontSize: 18 }}
                                    >
                                        {chat.message}
                                    </Text>
                                </View>
                            );
                        })}
                    <Text
                        style={{
                            position: 'absolute',
                            fontSize: 12,
                            bottom: 30,
                            left: 0,
                            right: 0,
                            textAlign: 'center',
                            color: 'grey',
                        }}
                        render={({ pageNumber, totalPages }) =>
                            `${pageNumber} / ${totalPages}`
                        }
                        fixed
                    />
                </Page>
            )}
            {props.mapLocations && (
                <Page
                    style={{
                        paddingTop: 35,
                        paddingBottom: 65,
                        paddingHorizontal: 35,
                    }}
                >
                    <Text
                        style={{
                            fontSize: 24,
                            textAlign: 'center',
                            marginBottom: 10,
                        }}
                    >
                        Kartobjekt logg:
                    </Text>
                    <View style={{ borderBottomWidth: 4 }} />
                    {props.mapLocations &&
                        props.mapLocations.map((mapLoc, index) => {
                            return (
                                <View
                                    key={index}
                                    style={{
                                        padding: 10,
                                        margin: 5,
                                        borderWidth: 2,
                                    }}
                                >
                                    <Text
                                        style={{
                                            borderBottomStyle: 'dashed',
                                            borderBottomWidth: 2,
                                            fontSize: 16,
                                            paddingBottom: 10,
                                        }}
                                    >
                                        {mapLoc.authorName},{' '}
                                        {moment
                                            .unix(mapLoc.timestamp.seconds)
                                            .format(
                                                'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                            )}
                                    </Text>
                                    {mapLoc.type === 2 && (
                                        <Text>Type: Polygon</Text>
                                    )}
                                    {mapLoc.type === 3 && (
                                        <Text>Type: Polyline</Text>
                                    )}
                                    {mapLoc.iconType && (
                                        <View>
                                            <Text>
                                                Type markør: {mapLoc.iconType}
                                            </Text>
                                            <Text>
                                                Pos:
                                                {mapLoc.geo[0].lat},{' '}
                                                {mapLoc.geo[0].lng}
                                            </Text>
                                        </View>
                                    )}
                                    <Text
                                        style={{ marginTop: 10, fontSize: 18 }}
                                    >
                                        {mapLoc.info}
                                    </Text>
                                </View>
                            );
                        })}
                    <Text
                        style={{
                            position: 'absolute',
                            fontSize: 12,
                            bottom: 30,
                            left: 0,
                            right: 0,
                            textAlign: 'center',
                            color: 'grey',
                        }}
                        render={({ pageNumber, totalPages }) =>
                            `${pageNumber} / ${totalPages}`
                        }
                        fixed
                    />
                </Page>
            )}
        </Document>
    );
};

export default function HistoricIncident() {
    const { incidentId } = useParams();
    const incidentRef = db.collection('incidents').doc(incidentId);
    const chatRef = db
        .collection('incidents')
        .doc(incidentId)
        .collection('chat')
        .orderBy('timestamp', 'asc');
    const mapLocRef = db
        .collection('incidents')
        .doc(incidentId)
        .collection('mapLog')
        .orderBy('timestamp', 'asc');

    const [incident, incLoading, incError] = useDocumentDataOnce(incidentRef);
    const [chats, chatLoading, chatError] = useCollectionDataOnce(chatRef);
    const [
        mapLocations,
        mapLocationsLoading,
        mapLocationError,
    ] = useCollectionDataOnce(mapLocRef, {
        idField: 'mapLocId',
    });
    return useMemo(() => {
        if (!incLoading || !chatLoading || !mapLocationsLoading) {
            return (
                <Container maxWidth='lg' style={{ marginTop: 80 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PDFDownloadLink
                                document={
                                    <PDFReport
                                        incident={incident}
                                        chats={chats}
                                        mapLocations={mapLocations}
                                    />
                                }
                                filename='testdoc.pdf'
                                style={{
                                    textDecoration: 'none',
                                    width: '100%',
                                    padding: '10px',
                                    color: '#4a4a4a',
                                    backgroundColor: '#f2f2f2',
                                    border: '1px solid #4a4a4a',
                                }}
                            >
                                {({ blob, url, loading, error }) =>
                                    loading
                                        ? 'Laster dokument...'
                                        : 'Last ned PDF!'
                                }
                            </PDFDownloadLink>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                component={Link}
                                to='/historikk'
                                variant='contained'
                                color='secondary'
                            >
                                Gå tilbake
                            </Button>
                        </Grid>
                    </Grid>
                    {incident && (
                        <>
                            <Typography
                                style={{
                                    fontSize: 24,
                                    textAlign: 'center',
                                    marginBottom: 10,
                                }}
                            >
                                {incident.title}
                            </Typography>
                            <Divider />
                            <Typography
                                style={{
                                    fontSize: 16,
                                    textAlign: 'left',
                                    borderBottomStyle: 'dashed',
                                    borderBottomWidth: 2,
                                    paddingBottom: 5,
                                    paddingTop: 10,
                                    marginBottom: 5,
                                }}
                            >
                                Startet av: {incident.ownerName}
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 16,
                                    textAlign: 'left',
                                    marginBottom: 5,
                                }}
                            >
                                Startet:{' '}
                                {moment
                                    .unix(incident.started.seconds)
                                    .format('DD.MM-YYYY[, kl. ]HH:mm:ss')}
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 16,
                                    paddingBottom: 5,
                                    textAlign: 'left',
                                    marginBottom: 10,
                                    borderBottomStyle: 'dashed',
                                    borderBottomWidth: 2,
                                }}
                            >
                                Avsluttet:{' '}
                                {moment
                                    .unix(incident.lastUpdate.seconds)
                                    .format('DD.MM-YYYY[, kl. ]HH:mm:ss')}
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 16,
                                    textAlign: 'left',
                                    paddingBottom: 5,
                                    marginBottom: 10,
                                    borderBottomStyle: 'dashed',
                                    borderBottomWidth: 2,
                                }}
                            >
                                Lokasjon: {incident.location}
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 20,
                                    fontWeight: 'ultrabold',
                                }}
                            >
                                Beskrivelse:
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 16,
                                    margin: 12,
                                }}
                            >
                                {incident.description}
                            </Typography>

                            <Typography
                                style={{
                                    position: 'absolute',
                                    fontSize: 12,
                                    bottom: 30,
                                    left: 0,
                                    right: 0,
                                    textAlign: 'center',
                                    color: 'grey',
                                }}
                                render={({ pageNumber, totalPages }) =>
                                    `${pageNumber} / ${totalPages}`
                                }
                                fixed
                            />
                        </>
                    )}
                    {chats && (
                        <>
                            <Typography
                                style={{
                                    fontSize: 24,
                                    textAlign: 'center',
                                    marginBottom: 10,
                                }}
                            >
                                Chat logg:
                            </Typography>
                            <Divider />
                            {chats &&
                                chats.map((chat, index) => {
                                    return (
                                        <>
                                            <Typography
                                                style={{
                                                    borderBottomStyle: 'dashed',
                                                    borderBottomWidth: 2,
                                                    fontSize: 16,
                                                    paddingBottom: 10,
                                                }}
                                            >
                                                {chat.authorName},{' '}
                                                {moment
                                                    .unix(
                                                        chat.timestamp.seconds
                                                    )
                                                    .format(
                                                        'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                                    )}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    marginTop: 10,
                                                    fontSize: 18,
                                                }}
                                            >
                                                {chat.message}
                                            </Typography>
                                        </>
                                    );
                                })}
                        </>
                    )}
                    {mapLocations && (
                        <>
                            <Typography
                                style={{
                                    fontSize: 24,
                                    textAlign: 'center',
                                    marginBottom: 10,
                                }}
                            >
                                Kartobjekt logg:
                            </Typography>
                            <Divider />
                            {mapLocations &&
                                mapLocations.map((mapLoc, index) => {
                                    return (
                                        <>
                                            <Typography
                                                style={{
                                                    borderBottomStyle: 'dashed',
                                                    borderBottomWidth: 2,
                                                    fontSize: 16,
                                                    paddingBottom: 10,
                                                }}
                                            >
                                                {mapLoc.authorName},{' '}
                                                {moment
                                                    .unix(
                                                        mapLoc.timestamp.seconds
                                                    )
                                                    .format(
                                                        'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                                    )}
                                            </Typography>
                                            {mapLoc.type === 2 && (
                                                <Text>Type: Polygon</Text>
                                            )}
                                            {mapLoc.type === 3 && (
                                                <Text>Type: Polyline</Text>
                                            )}
                                            {mapLoc.iconType && (
                                                <View>
                                                    <Text>
                                                        Type markør:{' '}
                                                        {mapLoc.iconType}
                                                    </Text>
                                                    <Text>
                                                        Pos:
                                                        {
                                                            mapLoc.geo[0].lat
                                                        }, {mapLoc.geo[0].lng}
                                                    </Text>
                                                </View>
                                            )}
                                            <Typography
                                                style={{
                                                    marginTop: 10,
                                                    fontSize: 18,
                                                }}
                                            >
                                                {mapLoc.info}
                                            </Typography>
                                        </>
                                    );
                                })}
                        </>
                    )}
                    ;
                </Container>
            );
        } else {
            return <Container maxWidth='lg'>Laster...</Container>;
        }
    }, [
        incLoading,
        chatLoading,
        mapLocationsLoading,
        incident,
        chats,
        mapLocations,
    ]);
}
