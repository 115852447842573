import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    List,
} from '@material-ui/core';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { db } from '../services/firebase';
import {
    Divider,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { NewReleases as NewReleasesIcon } from '@material-ui/icons';
import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function Historic() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedIncident, setSelectedIncident] = useState(null);
    const history = useHistory();
    const incidentListRef = db
        .collection('incidents')
        .where('ended', '==', true);
    const [incidentList, incLoading, incError] = useCollectionDataOnce(
        incidentListRef,
        {
            idField: 'incidentid',
        }
    );

    if (incidentList) {
        incidentList.sort((a, b) => {
            return b.started - a.started;
        });
    }
    return (
        <>
            {selectedIncident && (
                <Dialog
                    fullWidth={true}
                    maxWidth='md'
                    open={dialogOpen}
                    onClose={() => {
                        setSelectedIncident(null);
                        setDialogOpen(false);
                    }}
                    scroll='paper'
                >
                    <DialogTitle>
                        Hendelseslogg:{' '}
                        {selectedIncident && selectedIncident.title}
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant='subtitle1'>
                            <strong>Lokasjon: </strong>
                        </Typography>
                        <Typography variant='body1'>
                            {selectedIncident.location}
                        </Typography>
                        <Divider />
                        <Typography variant='subtitle1'>
                            <strong>Beskrivelse:</strong>
                        </Typography>
                        <Typography variant='body1'>
                            {selectedIncident.description}
                        </Typography>
                        <Divider />
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant='subtitle1'>
                                    <strong>Startet av: </strong>
                                </Typography>
                                <Typography variant='body1'>
                                    {selectedIncident.ownerName},{' '}
                                    {moment
                                        .unix(selectedIncident.started.seconds)
                                        .format('DD.MM-YYYY[, kl. ]HH:mm:ss')}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='subtitle1'>
                                    <strong>Avsluttet: </strong>
                                </Typography>
                                <Typography variant='body1'>
                                    {moment
                                        .unix(
                                            selectedIncident.lastUpdate.seconds
                                        )
                                        .format('DD.MM-YYYY[, kl. ]HH:mm:ss')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => {
                                history.push(
                                    '/historikk/' + selectedIncident.incidentid
                                );
                            }}
                        >
                            Vis hendelse
                        </Button>
                        <Button
                            variant='outlined'
                            color='secondary'
                            onClick={() => {
                                setSelectedIncident(null);
                                setDialogOpen(false);
                            }}
                        >
                            Avbryt
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Container maxWidth='lg' style={{ paddingTop: 80 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <List>
                            {incidentList &&
                                incidentList.map((listitem) => {
                                    return (
                                        <ListItem>
                                            <ListItem
                                                key={listitem.incidentid}
                                                button
                                                onClick={() => {
                                                    setSelectedIncident(
                                                        listitem
                                                    );
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <ListItemAvatar>
                                                    <NewReleasesIcon />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <b>
                                                            {listitem.title +
                                                                ', ' +
                                                                moment
                                                                    .unix(
                                                                        listitem
                                                                            .started
                                                                            .seconds
                                                                    )
                                                                    .format(
                                                                        'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                                                    )}
                                                        </b>
                                                    }
                                                    secondary={
                                                        <Typography variant='body2'>
                                                            Aksjon opprettet av:{' '}
                                                            {listitem.ownerName}
                                                            <br />
                                                            Siste oppdatering:{' '}
                                                            {moment
                                                                .unix(
                                                                    listitem
                                                                        .lastUpdate
                                                                        .seconds
                                                                )
                                                                .format(
                                                                    'DD.MM-YYYY[, kl. ]HH:mm:ss'
                                                                )}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </ListItem>
                                    );
                                })}
                        </List>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
