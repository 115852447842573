import { createContext, useContext, useState } from 'react';

const ToolbarContext = createContext();

export function useToolbar() {
    return useContext(ToolbarContext);
}

export const ToolbarProvider = ({ children }) => {
    const [toggleGeoLoc, setToggleGeoLoc] = useState(false);
    const [toggleSearch, setToggleSearch] = useState(false);
    const [toggleInfo, setToggleInfo] = useState(false);
    const [toggleTools, setToggleTools] = useState(false);
    const [toggleChat, setToggleChat] = useState(false);
    const [nUnreadChat, setNUnreadChat] = useState(0);
    const [createMarker, setCreateMarker] = useState(false);
    const [createPoly, setCreatePoly] = useState(false);
    const [createPolyLine, setCreatePolyLine] = useState(false);
    const [createCircle, setCreateCircle] = useState(false);

    const value = {
        toggleGeoLoc,
        setToggleGeoLoc,
        toggleSearch,
        setToggleSearch,
        toggleInfo,
        setToggleInfo,
        toggleTools,
        setToggleTools,
        toggleChat,
        setToggleChat,
        createMarker,
        setCreateMarker,
        createPoly,
        setCreatePoly,
        createPolyLine,
        setCreatePolyLine,
        createCircle,
        setCreateCircle,
        nUnreadChat,
        setNUnreadChat,
    };
    return (
        <ToolbarContext.Provider value={value}>
            {children}
        </ToolbarContext.Provider>
    );
};
