import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import SignupCard from '../components/SignupCard';
import { useHistory } from 'react-router-dom';

const Signup = () => {
    const [error, setError] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [repEmail, setRepEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repPassword, setRepPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const { signup, updateDisplayName, currentUser } = useAuth();
    const history = useHistory();

    const handleSubmit = async () => {
        if (displayName.length < 3) {
            return setError('Navn kan ikke være under 3 bokstaver...');
        }
        if (email.length < 3) {
            return setError('Email kan ikke være under 3 bokstaver...');
        }
        if (password.length < 6) {
            return setError('Passord kan ikke være under 6 tegn...');
        }
        if (email !== repEmail) {
            return setError('Emailene er ikke like...');
        }
        if (password !== repPassword) {
            return setError('Passordene er ikke like...');
        }
        try {
            setError('');
            setLoading(true);
            await signup(email, password);
        } catch {
            setError('Klarte ikke å opprette en konto');
        }
        setLoading(false);
    };
    useEffect(() => {
        if (currentUser && displayName !== '') {
            updateDisplayName(displayName);
            history.push('/');
        }
    }, [currentUser]);
    return (
        <SignupCard
            displayName={displayName}
            setDisplayName={setDisplayName}
            email={email}
            setEmail={setEmail}
            repEmail={repEmail}
            setRepEmail={setRepEmail}
            password={password}
            setPassword={setPassword}
            repPassword={repPassword}
            setRepPassword={setRepPassword}
            handleSubmit={handleSubmit}
            loading={loading}
            error={error}
        />
    );
};

export default Signup;
