import { Avatar, Box, Divider, Paper, Typography } from '@material-ui/core';
import squareAvatar from '../../assets/orgLogo/square.png';
import helseAvatar from '../../assets/orgLogo/Helse.png';
import politiAvatar from '../../assets/orgLogo/Politi.png';
import brannAvatar from '../../assets/orgLogo/Brann.png';
import observatorAvatar from '../../assets/orgLogo/Observator.png';
import moment from 'moment';

export default function ChatMessage(props) {
    let timestampString = '';
    let backgroundColor = '#f5f5f5';
    let color = 'black';
    if (props.chat.timestamp) {
        timestampString = moment
            .unix(props.chat.timestamp.seconds)
            .format('DD.MM-YYYY[, kl. ]HH:mm:ss');
    }
    let currentAvatar = observatorAvatar;
    if (props.chat.org) {
        if (props.chat.org === 'brann') {
            currentAvatar = brannAvatar;
        }
        if (props.chat.org === 'helse') {
            currentAvatar = helseAvatar;
        }
        if (props.chat.org === 'politi') {
            currentAvatar = politiAvatar;
        }
    }
    if (props.chat.authorName === 'SQUARE') {
        currentAvatar = squareAvatar;
        backgroundColor = '#bdbdbd';
    }
    return (
        <Paper
            variant='elevation'
            elevation={5}
            style={{
                marginTop: 20,
                padding: 10,
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: backgroundColor,
                color: color,
            }}
        >
            <Box paddingRight='20px' width='180px'>
                <Avatar src={currentAvatar} />
                <Typography variant='body1'>{props.chat.authorName}</Typography>
                <Typography variant='body2'>{timestampString}</Typography>
            </Box>
            <Divider orientation='vertical' flexItem />
            <Box style={{ flex: 1, paddingLeft: 20 }}>{props.chat.message}</Box>
        </Paper>
    );
}
