import { Box, TextField } from '@material-ui/core';
import OpenStreetMapV2 from '../components/OpenStreetMapV2';
import firebase from 'firebase/app';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../services/firebase';
import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import {
    useCollectionData,
    useDocumentData,
} from 'react-firebase-hooks/firestore';
import { useToolbar } from '../contexts/ToolbarContext';
import IncidentInfo from '../components/IncidentInfo';
import PoiInfo from '../components/PoiInfo';
import NoIncident from '../components/incident/NoIncident';
import ChatV5 from '../components/chat/ChatV5';
import CreateMapFeatureDialog from '../components/CreateMapFeatureDialog';
import GeoSearch from '../components/GeoSearch';

const IncidentV3 = () => {
    const {
        toggleGeoLoc,
        setToggleGeoLoc,
        toggleSearch,
        setToggleSearch,
        toggleInfo,
        setToggleInfo,
        toggleTools,
        setToggleTools,
        toggleChat,
        setToggleChat,
        createMarker,
        setCreateMarker,
        createPoly,
        setCreatePoly,
        createPolyLine,
        setCreatePolyLine,
        createCircle,
        setCreateCircle,
        setNUnreadChat,
    } = useToolbar();
    const { id } = useParams();
    const { currentUser } = useAuth();
    const history = useHistory();
    const [togglePoi, setTogglePoi] = useState(false);
    const [activePoi, setActivePoi] = useState(null);
    const [toggleEnd, setToggleEnd] = useState(false);
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [poiInfo, setPoiInfo] = useState('');
    const [poiType, setPoiType] = useState('Informasjon');
    const [poiTmpData, setPoiTmpData] = useState('');
    const [polyInfo, setPolyInfo] = useState('');
    const [polyData, setPolyData] = useState([]);
    const [polyColor, setPolyColor] = useState('white');
    const [polyLineData, setPolyLineData] = useState([]);
    const [polyLineLength, setPolyLineLength] = useState(0);
    const [polyLineInfo, setPolyLineInfo] = useState('');
    const [polyLineWidth, setPolyLineWidth] = useState(8);
    const [showSystem, setShowSystem] = useState(true);
    const [geoSearchField, setGeoSearchField] = useState('');
    const [geoSearchResults, setGeoSearchResults] = useState([]);
    const chatRef = db
        .collection('incidents')
        .doc(id)
        .collection('chat')
        .orderBy('timestamp', 'asc');
    const [chats, chatLoading, chatError] = useCollectionData(chatRef, {
        idField: 'chatId',
    });
    const incidentRef = db.collection('incidents').doc(id);
    const [incident, incLoading, incError] = useDocumentData(incidentRef);
    const mapLocRef = db.collection('incidents').doc(id).collection('mapLog');

    const [mapLocations, mapLocLoading, mapLocError] = useCollectionData(
        mapLocRef,
        {
            idField: 'mapLocId',
        }
    );
    const [locationList, setLocationList] = useState([]);
    const [location, setLocation] = useState(null);
    const [position, setPosition] = useState(null);
    const dummyScrollTarget = useRef();
    const geoSearchProvider = new OpenStreetMapProvider({
        params: {
            'accept-language': 'no',
            countrycodes: 'no',
        },
    });

    const handleGeoSearch = async (evt) => {
        if (evt.target.value.length > 3) {
            setLocationList(
                await geoSearchProvider.search({ query: evt.target.value })
            );
        } else {
            setLocationList([]);
        }
    };
    const handleFindMe = () => {
        console.log('Find me');
    };
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371;
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) *
                Math.cos(lat2 * (Math.PI / 180)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        return d;
    };
    const handleSendMessage = async (msg) => {
        setIsSubmitting(true);
        if (message !== '') {
            await db.collection('incidents').doc(id).collection('chat').add({
                authorid: currentUser.uid,
                authorName: currentUser.displayName,
                message: message,
                timestamp: firebase.firestore.Timestamp.now(),
            });
            setMessage('');
            setIsSubmitting(false);
        }
        if (msg) {
            await db.collection('incidents').doc(id).collection('chat').add({
                authorid: currentUser.uid,
                isSystemMsg: true,
                authorName: 'SQUARE',
                message: msg,
                timestamp: firebase.firestore.Timestamp.now(),
            });
            setIsSubmitting(false);
        }
    };
    const handleMapClick = async (e) => {
        if (createMarker) {
            setPoiTmpData({ lat: e.latlng.lat, lng: e.latlng.lng });
        }
        if (createPoly) {
            setPolyData((polyData) => [
                ...polyData,
                { lat: e.latlng.lat, lng: e.latlng.lng },
            ]);
        }
        if (createPolyLine) {
            if (polyLineData.length > 0) {
                const initCord = polyLineData[polyLineData.length - 1];
                const newCord = { lat: e.latlng.lat, lng: e.latlng.lng };
                setPolyLineLength(
                    polyLineLength +
                        (await calculateDistance(
                            initCord.lat,
                            initCord.lng,
                            newCord.lat,
                            newCord.lng
                        ))
                );
            }
            setPolyLineData((polyLineData) => [
                ...polyLineData,
                { lat: e.latlng.lat, lng: e.latlng.lng },
            ]);
        }
    };
    const handleSavePoly = async () => {
        setIsSubmitting(true);
        try {
            if (polyData.length > 2) {
                let poi = await db
                    .collection('incidents')
                    .doc(id)
                    .collection('mapLog')
                    .add({
                        authorid: currentUser.uid,
                        authorName: currentUser.displayName,
                        info: polyInfo,
                        geo: polyData,
                        polyColor: polyColor,
                        timestamp: firebase.firestore.Timestamp.now(),
                        type: 2,
                    });
                if (poi.id) {
                    let msg =
                        currentUser.displayName +
                        ' har opprettet et polygon i kartet.';
                    if (polyInfo.length > 1) {
                        msg += ' Info: ' + polyInfo;
                    }
                    handleSendMessage(msg);
                }
                setPolyData([]);
                setPolyInfo('');
                setPolyColor('white');
                setCreatePoly(false);
                setIsSubmitting(false);
            }
        } catch {
            setIsSubmitting(false);
        }
    };
    const handleSavePolyLine = async () => {
        setIsSubmitting(true);
        try {
            if (polyLineData.length > 1) {
                const poi = await db
                    .collection('incidents')
                    .doc(id)
                    .collection('mapLog')
                    .add({
                        authorid: currentUser.uid,
                        authorName: currentUser.displayName,
                        lineLength: polyLineLength,
                        info: polyLineInfo,
                        geo: polyLineData,
                        polyLineWidth: polyLineWidth,
                        timestamp: firebase.firestore.Timestamp.now(),
                        type: 3,
                    });
                if (poi.id) {
                    let msg =
                        currentUser.displayName +
                        ' har opprettet en linje i kartet.';
                    if (polyLineInfo.length > 1) {
                        msg += ' Info: ' + polyLineInfo;
                    }
                    handleSendMessage(msg);
                }
                setPolyLineData([]);
                setPolyLineInfo('');
                setPolyLineLength(0);
                setPolyLineWidth(8);
                setCreatePolyLine(false);
                setIsSubmitting(false);
            }
        } catch {
            setIsSubmitting(false);
        }
    };
    const handleSavePOI = async () => {
        setIsSubmitting(true);
        try {
            if (poiTmpData !== '') {
                const poi = await db
                    .collection('incidents')
                    .doc(id)
                    .collection('mapLog')
                    .add({
                        authorid: currentUser.uid,
                        authorName: currentUser.displayName,
                        info: poiInfo,
                        iconType: poiType,
                        geo: [poiTmpData],
                        timestamp: firebase.firestore.Timestamp.now(),
                        type: 1,
                    });
                if (poi.id) {
                    let msg =
                        currentUser.displayName +
                        ' har opprettet et POI av type "' +
                        poiType +
                        '" på lokasjon: Lat: ' +
                        poiTmpData.lat.toFixed(4) +
                        ' Lon: ' +
                        poiTmpData.lng.toFixed(4);
                    if (poiInfo.length > 0) {
                        msg += '. Info: ' + poiInfo;
                    }
                    handleSendMessage(msg);
                }
                setPoiInfo('');
                setPoiType('Informasjon');
                setCreateMarker(false);
                setPoiTmpData('');
                setIsSubmitting(false);
            }
        } catch {
            setIsSubmitting(false);
        }
    };
    const handleDeleteMapLoc = async (mapDocId) => {
        await db
            .collection('incidents')
            .doc(id)
            .collection('mapLog')
            .doc(mapDocId)
            .delete();
        setActivePoi(null);
        setTogglePoi(false);
    };
    const handleCloseIncident = async () => {
        await db.collection('incidents').doc(id).update({
            ended: true,
            lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
        });
        history.push('/');
    };
    return (
        <>
            <IncidentInfo
                toggleInfo={toggleInfo}
                setToggleInfo={setToggleInfo}
                incident={incident}
                currentUser={currentUser}
                handleCloseIncident={handleCloseIncident}
                setPosition={setPosition}
                toggleEnd={toggleEnd}
                setToggleEnd={setToggleEnd}
            />
            <PoiInfo
                activePoi={activePoi}
                setActivePoi={setActivePoi}
                togglePoi={togglePoi}
                setTogglePoi={setTogglePoi}
                handleDeleteMapLoc={handleDeleteMapLoc}
                incident={incident}
                currentUser={currentUser}
            />
            <NoIncident
                id={id}
                incLoading={incLoading}
                incError={incError}
                incident={incident}
            />
            {toggleChat ? (
                <ChatV5
                    chats={chats}
                    chatLoading={chatLoading}
                    chatError={chatError}
                    message={message}
                    setMessage={setMessage}
                    handleSendMessage={handleSendMessage}
                    currentUser={currentUser}
                    dummyScrollTarget={dummyScrollTarget}
                    isSubmitting={isSubmitting}
                    showSystem={showSystem}
                    setShowSystem={setShowSystem}
                />
            ) : (
                <Box
                    height='100vh'
                    overflow='hidden'
                    paddingTop='65px'
                    display='flex'
                    flexDirection='column'
                >
                    {toggleSearch && (
                        <Box padding={1}>
                            <GeoSearch
                                handleGeoSearch={handleGeoSearch}
                                locationList={locationList}
                                location={location}
                                setLocation={setLocation}
                                setLocationList={setLocationList}
                                setPosition={setPosition}
                                setToggleSearch={setToggleSearch}
                            />
                        </Box>
                    )}
                    <OpenStreetMapV2
                        handleMapClick={handleMapClick}
                        createPolyLine={createPolyLine}
                        createPoly={createPoly}
                        createMarker={createMarker}
                        setCreateMarker={setCreateMarker}
                        mapLocations={mapLocations}
                        polyData={polyData}
                        polyLineData={polyLineData}
                        polyLineWidth={polyLineWidth}
                        currentUser={currentUser}
                        handleDeleteMapLoc={handleDeleteMapLoc}
                        incident={incident}
                        polyColor={polyColor}
                        setToggleInfo={setToggleInfo}
                        position={position}
                        setPosition={setPosition}
                        togglePoi={togglePoi}
                        setTogglePoi={setTogglePoi}
                        activePoi={activePoi}
                        setActivePoi={setActivePoi}
                        setPoiType={setPoiType}
                        poiType={poiType}
                        poiTmpData={poiTmpData}
                    />
                </Box>
            )}
            {(createMarker || createPoly || createPolyLine) && (
                <CreateMapFeatureDialog
                    createMarker={createMarker}
                    setCreateMarker={setCreateMarker}
                    poiInfo={poiInfo}
                    setPoiInfo={setPoiInfo}
                    setPoiType={setPoiType}
                    poiType={poiType}
                    createPolyLine={createPolyLine}
                    setCreatePolyLine={setCreatePolyLine}
                    polyLineInfo={polyLineInfo}
                    setPolyLineInfo={setPolyLineInfo}
                    polyLineWidth={polyLineWidth}
                    setPolyLineWidth={setPolyLineWidth}
                    setPolyLineData={setPolyLineData}
                    handleSavePolyLine={handleSavePolyLine}
                    createPoly={createPoly}
                    setCreatePoly={setCreatePoly}
                    polyInfo={polyInfo}
                    setPolyInfo={setPolyInfo}
                    polyColor={polyColor}
                    setPolyColor={setPolyColor}
                    setPolyData={setPolyData}
                    handleSavePoly={handleSavePoly}
                    geoSearchResults={geoSearchResults}
                    geoSearchField={geoSearchField}
                    setGeoSearchField={setGeoSearchField}
                    handleFindMe={handleFindMe}
                    handleSavePOI={handleSavePOI}
                    poiTmpData={poiTmpData}
                    setPoiTmpData={setPoiTmpData}
                    isSubmitting={isSubmitting}
                />
            )}
        </>
    );
};

export default IncidentV3;
