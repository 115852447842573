import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Send as SendIcon } from '@material-ui/icons';
import ChatMessage from './ChatMessage';

export default function ChatV5(props) {
    useEffect(() => {
        props.dummyScrollTarget.current.scrollIntoView({ behavior: 'smooth' });
    }, [props.dummyScrollTarget]);
    const sendMessageByButton = () => {
        if (!props.isSubmitting) {
            props.handleSendMessage();
        }
    };

    const sendMessageByKey = (evt) => {
        if (!props.isSubmitting) {
            if (evt.key === 'Enter') {
                props.handleSendMessage();
            }
        }
    };
    return (
        <Box
            height='100vh'
            overflow='hidden'
            paddingTop='70px'
            display='flex'
            flexDirection='column'
        >
            <Container
                style={{ height: '100%', paddingTop: 10, paddingBottom: 10 }}
            >
                <Card
                    style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <CardHeader
                        title='Tidslinje/Logg::'
                        action={
                            <FormControlLabel
                                labelPlacement='start'
                                label='Vis systemmeldinger'
                                checked={props.showSystem}
                                onChange={() =>
                                    props.setShowSystem(!props.showSystem)
                                }
                                control={<Checkbox color='primary' />}
                            />
                        }
                        style={{ flex: 0 }}
                    />
                    <CardContent
                        style={{
                            flex: 'auto',
                            overflow: 'auto',
                        }}
                    >
                        {props.chats &&
                            props.chats.map((chat) => {
                                if (chat.isSystemMsg && !props.showSystem) {
                                    return null;
                                }
                                return (
                                    <ChatMessage
                                        key={chat.chatId}
                                        chat={chat}
                                    />
                                );
                            })}
                        <div ref={props.dummyScrollTarget}></div>
                    </CardContent>
                    <CardActions style={{ flex: 0, marginBottom: 10 }}>
                        <TextField
                            fullWidth
                            variant='outlined'
                            label='Melding'
                            value={props.message}
                            onChange={(evt) =>
                                props.setMessage(evt.target.value)
                            }
                            onKeyPress={sendMessageByKey}
                        />
                        <IconButton
                            variant='contained'
                            color='primary'
                            disabled={props.isSubmitting}
                            onClick={sendMessageByButton}
                        >
                            <SendIcon />
                        </IconButton>
                    </CardActions>
                </Card>
            </Container>
        </Box>
    );
}
