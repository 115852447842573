import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    TextField,
    Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
const SignupCard = (props) => {
    return (
        <Container maxWidth='sm'>
            <Card style={{ marginTop: 80 }}>
                <CardHeader title='Registrer deg' />
                <CardContent>
                    {props.error && (
                        <Alert severity='error' style={{ marginBottom: 20 }}>
                            {props.error}
                        </Alert>
                    )}
                    <TextField
                        variant='outlined'
                        label='Navn'
                        type='text'
                        value={props.displayName}
                        onChange={(evt) => {
                            props.setDisplayName(evt.target.value);
                        }}
                        fullWidth
                        style={{ marginBottom: 20 }}
                    />
                    <TextField
                        variant='outlined'
                        label='Email'
                        type='email'
                        value={props.email}
                        onChange={(evt) => {
                            props.setEmail(evt.target.value);
                        }}
                        fullWidth
                        style={{ marginBottom: 20 }}
                    />
                    <TextField
                        variant='outlined'
                        label='Gjenta Email'
                        type='email'
                        fullWidth
                        value={props.repEmail}
                        onChange={(evt) => {
                            props.setRepEmail(evt.target.value);
                        }}
                        style={{ marginBottom: 20 }}
                    />
                    <TextField
                        variant='outlined'
                        label='Passord'
                        type='password'
                        fullWidth
                        value={props.password}
                        onChange={(evt) => {
                            props.setPassword(evt.target.value);
                        }}
                        style={{ marginBottom: 20 }}
                    />
                    <TextField
                        variant='outlined'
                        label='Gjenta passord'
                        type='password'
                        fullWidth
                        value={props.repPassword}
                        onChange={(evt) => {
                            props.setRepPassword(evt.target.value);
                        }}
                        style={{ marginBottom: 20 }}
                    />
                    <Button
                        color='primary'
                        variant='contained'
                        fullWidth
                        onClick={props.handleSubmit}
                        disabled={props.loading}
                    >
                        Registrer deg
                    </Button>
                    <Typography variant='body1' style={{ marginTop: 20 }}>
                        Har du allerede bruker?
                    </Typography>
                    <Typography variant='body1'>
                        <Link
                            to='/innlogging'
                            style={{
                                textDecoration: 'none',
                                color: 'black',
                                fontWeight: 'bold',
                            }}
                        >
                            Trykk her for å logge inn...
                        </Link>
                    </Typography>
                </CardContent>
            </Card>
        </Container>
    );
};
export default SignupCard;
