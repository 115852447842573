import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';

import {
    iconAlarmsignal,
    iconAlarmsignal0,
    iconAlarmsignal1,
    iconAlarmsignal2,
    iconAlarmsignal3,
    iconInformasjon,
    iconHendelse,
} from '../components/MapIcons';

const UserSettings = () => {
    const { currentUser, updateDisplayName } = useAuth();
    const [message, setMessage] = useState('');
    const [displayName, setDisplayName] = useState(currentUser.displayName);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleUpdateDisplayName = async () => {
        try {
            setError('');
            setLoading(true);
            await updateDisplayName(displayName);
            setMessage('Visningsnavn oppdatert...');
        } catch {
            setError('Klarte ikke å logge inn...');
        }
        setLoading(false);
    };

    return (
        <Container maxWidth={false} style={{ paddingTop: 80 }}>
            <Card>
                <CardHeader title='Innstillinger' />
                <CardContent>
                    {message && <Alert severity='success'>{message}</Alert>}
                    {error && <Alert severity='error'>{error}</Alert>}
                    <TextField
                        variant='outlined'
                        type='text'
                        label='Ditt Visningsnavn'
                        value={displayName}
                        onChange={(evt) => {
                            setDisplayName(evt.target.value);
                        }}
                        fullWidth
                    />
                    <Button
                        variant='contained'
                        fullWidth
                        color='primary'
                        style={{ marginTop: 20 }}
                        onClick={handleUpdateDisplayName}
                        disabled={loading}
                    >
                        Oppdater visningsnavn
                    </Button>
                </CardContent>
            </Card>
        </Container>
    );
};

export default UserSettings;
