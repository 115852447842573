import {
    Card,
    CircularProgress,
    Container,
    Typography,
} from '@material-ui/core';

export default function NoIncident(props) {
    return (
        <>
            {props.incLoading ? (
                <Container maxWidth='xs'>
                    <Card
                        style={{ marginTop: '45vh', padding: 20 }}
                        elevation={8}
                    >
                        <Typography variant='h5'>Laster hendelse:</Typography>
                        <Typography variant='body1'>
                            Prøver å laste hendelse med id: {props.id}
                        </Typography>
                        <CircularProgress size={100} />
                    </Card>
                </Container>
            ) : null}
            {props.incError ||
                (!props.incLoading &&
                    (!props.incident || props.incident.ended) && (
                        <Container maxWidth='xs'>
                            <Card
                                style={{ marginTop: '45vh', padding: 20 }}
                                elevation={8}
                            >
                                <Typography variant='h5'>Feil:</Typography>
                                <Typography variant='body1'>
                                    Finnes ingen åpen hendelse med hendelsesnr:{' '}
                                    {props.id}
                                </Typography>
                            </Card>
                        </Container>
                    ))}
        </>
    );
}
