import { CircularProgress, Container } from '@material-ui/core';
import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../services/firebase';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const [showIncidentInfo, setShowIncidentInfo] = useState(false);

    const signup = (email, password) => {
        return auth.createUserWithEmailAndPassword(email, password);
    };
    const login = (email, password) => {
        return auth.signInWithEmailAndPassword(email, password);
    };
    const logout = () => {
        return auth.signOut();
    };
    const forgotPassword = (email) => {
        return auth.sendPasswordResetEmail(email);
    };
    const updateDisplayName = (displayName) => {
        return currentUser.updateProfile({
            displayName: displayName,
        });
    };
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setLoading(false);
        });
        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        signup,
        login,
        logout,
        forgotPassword,
        updateDisplayName,
        showIncidentInfo,
        setShowIncidentInfo,
    };
    return (
        <AuthContext.Provider value={value}>
            {loading ? (
                <Container maxWidth='xs'>
                    <CircularProgress size={300} />
                </Container>
            ) : (
                children
            )}
        </AuthContext.Provider>
    );
}
